import './assets/iconfont/iconfont.js';
import './assets/iconfont/iconfont.css';
import './index.css';

import React from 'react';
import ReactDOM from 'react-dom/client';
import { config as AmapReactConfig } from '@amap/amap-react';
import { ConfigProvider } from 'antd';
import zhCN from 'antd/locale/zh_CN';

import 'dayjs/locale/zh-cn';
import dayjs from 'dayjs';

import reportWebVitals from './reportWebVitals';
import Main from './views/main/Main';
import LoginInterceptor from './views/login/LoginInterceptor';

// amap configuration
AmapReactConfig.version = '2.0';
AmapReactConfig.key = '0c3d07d8ebae700b5652bbfc2cf18fb0';

// dayjs locale
dayjs.locale('zh-cn')

// render
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <ConfigProvider locale={zhCN}>
        <LoginInterceptor>
            <Main />
        </LoginInterceptor>
    </ConfigProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
