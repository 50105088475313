import axios, { AxiosResponse } from "axios";
import AxiosService from "./Axios";
import moment from "moment";

class AlarmEventApi {
    
    public async queryViews(request: QueryAlarmEventsRequest): Promise<AxiosResponse<any>> {
        // mock
        // return AxiosService.post('/alarm-event/view/query', request);
        return {data: {data: {
            elements: [
                {
                    device: {
                        "id": "device-1721553149440-000-000-0",
                        "name": "卷扬机-001",
                        "remarks": "",
                        "createdTime": "1721553149439",
                        "createdBy": "",
                        "updatedTime": "1721553149439",
                        "updatedBy": "0",
                        "tenantId": "0",
                        "deleted": false,
                        "nodeId": "node-1721575069747-000-000-0",
                        "deviceNo": "WINCH-01",
                        "deviceTemplateId": "device-template-1721550223687-000-000-0",
                        "deviceSettings": "{\"system\":{\"httpServer\":\"\",\"mqttServer\":\"\"},\"heartBeat\":{\"interval\":60,\"intervalUnit\":\"S\",\"intervalMs\":60000},\"adc\":{\"ch1\":{\"enabled\":1,\"publishInterval\":10,\"publishIntervalUnit\":\"S\",\"publishIntervalMs\":10000},\"ch2\":{\"enabled\":1,\"publishInterval\":10,\"publishIntervalUnit\":\"S\",\"publishIntervalMs\":10000},\"ch3\":{\"enabled\":1,\"publishInterval\":10,\"publishIntervalUnit\":\"S\",\"publishIntervalMs\":10000},\"ch4\":{\"enabled\":1,\"publishInterval\":10,\"publishIntervalUnit\":\"S\",\"publishIntervalMs\":10000},\"ch5\":{\"enabled\":1,\"publishInterval\":10,\"publishIntervalUnit\":\"S\",\"publishIntervalMs\":10000},\"ch6\":{\"enabled\":1,\"publishInterval\":10,\"publishIntervalUnit\":\"S\",\"publishIntervalMs\":10000}}}",
                        "positioningMethod": "AUTO",
                        "geoLatitude": 0,
                        "geoLongitude": 0,
                        "lastSeenTime": null
                    },
                    event: {
                        "id": "1",
                        "deviceId": "device-1721553149440-000-000-0",
                        "details": "通道1超告警阈值，测量值：16.52，告警值：16.00",
                        "startTime": new Date().getTime(),
                        "endTime": new Date().getTime(),
                    }
                },{
                    device: {
                        "id": "device-1721553149440-000-000-0",
                        "name": "卷扬机-002",
                        "remarks": "",
                        "createdTime": "1721553149439",
                        "createdBy": "",
                        "updatedTime": "1721553149439",
                        "updatedBy": "0",
                        "tenantId": "0",
                        "deleted": false,
                        "nodeId": "node-1721575069747-000-000-0",
                        "deviceNo": "WINCH-01",
                        "deviceTemplateId": "device-template-1721550223687-000-000-0",
                        "deviceSettings": "{\"system\":{\"httpServer\":\"\",\"mqttServer\":\"\"},\"heartBeat\":{\"interval\":60,\"intervalUnit\":\"S\",\"intervalMs\":60000},\"adc\":{\"ch1\":{\"enabled\":1,\"publishInterval\":10,\"publishIntervalUnit\":\"S\",\"publishIntervalMs\":10000},\"ch2\":{\"enabled\":1,\"publishInterval\":10,\"publishIntervalUnit\":\"S\",\"publishIntervalMs\":10000},\"ch3\":{\"enabled\":1,\"publishInterval\":10,\"publishIntervalUnit\":\"S\",\"publishIntervalMs\":10000},\"ch4\":{\"enabled\":1,\"publishInterval\":10,\"publishIntervalUnit\":\"S\",\"publishIntervalMs\":10000},\"ch5\":{\"enabled\":1,\"publishInterval\":10,\"publishIntervalUnit\":\"S\",\"publishIntervalMs\":10000},\"ch6\":{\"enabled\":1,\"publishInterval\":10,\"publishIntervalUnit\":\"S\",\"publishIntervalMs\":10000}}}",
                        "positioningMethod": "AUTO",
                        "geoLatitude": 0,
                        "geoLongitude": 0,
                        "lastSeenTime": null
                    },
                    event: {
                        "id": "2",
                        "deviceId": "device-1721553149440-000-000-0",
                        "details": "通道2超告警阈值，测量值：17.22，告警值：16.00",
                        "startTime": new Date().getTime(),
                        "endTime": new Date().getTime(),
                    }
                },{
                    device: {
                        "id": "device-1721553149440-000-000-0",
                        "name": "卷扬机-003",
                        "remarks": "",
                        "createdTime": "1721553149439",
                        "createdBy": "",
                        "updatedTime": "1721553149439",
                        "updatedBy": "0",
                        "tenantId": "0",
                        "deleted": false,
                        "nodeId": "node-1721575069747-000-000-0",
                        "deviceNo": "WINCH-01",
                        "deviceTemplateId": "device-template-1721550223687-000-000-0",
                        "deviceSettings": "{\"system\":{\"httpServer\":\"\",\"mqttServer\":\"\"},\"heartBeat\":{\"interval\":60,\"intervalUnit\":\"S\",\"intervalMs\":60000},\"adc\":{\"ch1\":{\"enabled\":1,\"publishInterval\":10,\"publishIntervalUnit\":\"S\",\"publishIntervalMs\":10000},\"ch2\":{\"enabled\":1,\"publishInterval\":10,\"publishIntervalUnit\":\"S\",\"publishIntervalMs\":10000},\"ch3\":{\"enabled\":1,\"publishInterval\":10,\"publishIntervalUnit\":\"S\",\"publishIntervalMs\":10000},\"ch4\":{\"enabled\":1,\"publishInterval\":10,\"publishIntervalUnit\":\"S\",\"publishIntervalMs\":10000},\"ch5\":{\"enabled\":1,\"publishInterval\":10,\"publishIntervalUnit\":\"S\",\"publishIntervalMs\":10000},\"ch6\":{\"enabled\":1,\"publishInterval\":10,\"publishIntervalUnit\":\"S\",\"publishIntervalMs\":10000}}}",
                        "positioningMethod": "AUTO",
                        "geoLatitude": 0,
                        "geoLongitude": 0,
                        "lastSeenTime": null
                    },
                    event: {
                        "id": "3",
                        "deviceId": "device-1721553149440-000-000-0",
                        "details": "通道3超告警阈值，测量值：17.56，告警值：16.00",
                        "startTime": new Date().getTime(),
                        "endTime": new Date().getTime(),
                    }
                }
            ]
        }}} as any;
    }

    public async getStatistics(): Promise<any> {
        // mock
        let historical = [];
        for(let i=7; i>=1; i--) {
            historical.push({
                date: moment(new Date().getTime() - i * 1000 * 3600 * 24).format("MM/DD"),
                value: Math.round(Math.random() * 20),
            });
        }

        return {data: {data: {
            overview: {
                total: 8,
                today: 3,
                unprocessed: 3,
                processed: 6
            },
            historical: historical
        }}};
    }
}


interface QueryAlarmEventsRequest {
    deviceId?: string,
    pageNo: number,
    pageSize: number,
    startTime?: number,
    endTime?: number,
}

export default AlarmEventApi;