import DeviceApi from "../../api/DeviceApi";
import { CheckCircleFilled, CloseCircleFilled, DeleteFilled, SearchOutlined } from "@ant-design/icons";
import { Button, Input, Modal, Popconfirm, Select, Space, Table, message } from "antd";
import { ColumnType, TablePaginationConfig } from "antd/es/table";
import { useEffect, useState } from "react";
import { Route, Routes, useNavigate, useSearchParams } from "react-router-dom";
import moment from "moment";

import StringUtils from "../../util/StringUtils";
import CreateDeviceWizard from "./components/create-device-wizard/CreateDeviceWizard";
import DeviceDetails from "./components/device-details/DeviceDetails";
import { DeviceManagementWrapper, FilterItem, FilterItemLabel, TableWrapper, ToolBarLeftWrapper, ToolBarRightWrapper, ToolBarWrapper } from "./DeviceManagement.styled";
import DeviceTemplateApi from "../../api/DeviceTemplateApi";
import ProductApi from "../../api/ProductApi";
import KeyCodeUtils from "../../util/KeyCodeUtils";

interface DeviceManagementProps {

}

interface TableParams {
    pagination?: TablePaginationConfig;
}

const DeviceManagement: React.FC<DeviceManagementProps> = (props)=> {
    // api
    let deviceApi = new DeviceApi();
    let deviceTemplateApi = new DeviceTemplateApi();
    let productApi = new ProductApi();
    let [messageApi, contextHolder] = message.useMessage();
    let navigate = useNavigate();
    
    // params
    let [searchParams] = useSearchParams();

    // state
    let [deviceViews, setDeviceViews] = useState([]);
    let [openCreateDeviceModal, setOpenCreateDeviceModal] = useState(false);

    let [loading, setLoading] = useState(true);
    let [deviceTemplateId, setDeviceTemplateId] = useState(searchParams.get('deviceTemplateId'));
    let [productModel, setProductModel] = useState(searchParams.get('productModel'));
    let [search, setSearch] = useState("");
    let [tableParams, setTableParams] = useState<TableParams>({
        pagination: {
            current: 1,
            pageSize: 10,
            total: 0,
        },
    });

    let [deviceTemplateOptions, setDeviceTemplateOptions] = useState([]);
    let [productModelOptions, setProductModelOptions] = useState([]);
    
    // events
    let viewDeviceDetails = (device: any)=> {
        navigate(`./device/${device.device.id}`);
    };

    let onTableChange = (pagination)=> {
        tableParams.pagination = pagination;
        setTableParams({pagination: pagination});
        loadDeviceViews();
    };

    let onCreateDeviceClick = ()=> {
        setOpenCreateDeviceModal(true);
    };

    let onSearchKeyDown = (e)=> {
        if (e.keyCode == KeyCodeUtils.ENTER) {
            loadDeviceViews();
        }
    };

    let onDeleteDevice = async (deviceView: any)=> {
        await deviceApi.delete(deviceView.device.id);
        messageApi.open({
            type: 'success',
            content: '删除设备成功',
          });
        loadDeviceViews();
    };

    // load device views
    let loadDeviceViews = async ()=> {
        // loading animation
        setLoading(true);
        let deviceViewsPage = (await deviceApi.queryViews({
            deviceTemplateId: deviceTemplateId,
            productModel: productModel,
            search: search,
            pageNo: tableParams.pagination.current,
            pageSize: tableParams.pagination.pageSize,
        })).data.data;

        let newTableParams = {pagination: {...tableParams.pagination}};
        newTableParams.pagination.total = deviceViewsPage.totalElements;
        setTableParams(newTableParams);
        setDeviceViews(deviceViewsPage.elements);
        setLoading(false);
    };

    let loadDeviceTemplate = async ()=> {
        let deviceTemplates = (await deviceTemplateApi.getAll()).data.data;
        let deviceTemplateOptions = deviceTemplates.map((e)=> {
            return {
                label: e.name,
                value: e.id,
            };
        });
        setDeviceTemplateOptions(deviceTemplateOptions);
    };
    
    let loadProductModels = async ()=> {
        let products = (await productApi.getAll()).data.data;
        let productModelOptions = products.map((e)=> {
            return {
                label: e.model,
                value: e.model,
            };
        });
        setProductModelOptions(productModelOptions);
    };

    // column definition
    let columns: ColumnType<any>[] = [
        {
            title: '状态',
            dataIndex: 'node.status',
            key: "node.status",
            align: 'center',
            render: (text, record, index)=> {
                return record.node.status == 'ONLINE' 
                    ? <CheckCircleFilled style={{color: 'green'}} />
                    : <CloseCircleFilled style={{color: 'grey'}} />
            }
        },
        {
            title: '设备名称',
            dataIndex: 'device.name',
            key: 'device.name',
            align: 'center',
            render: (text, record, index)=> {
                return <a onClick={()=> viewDeviceDetails(record)}>{record.device.name}</a>
            }
        },
        {
            title: '序列号',
            dataIndex: 'node.sn',
            key: 'node.sn',
            align: 'center',
            render: (text, record, index)=> {
                return <span>{record.node.sn}</span>
            }
        },
        {
            title: '设备模板',
            dataIndex: 'template.name',
            key: 'template.name',
            align: 'center',
            render: (text, record, index)=> {
                return <span>{record.deviceTemplate?.name}</span>
            }
        },
        {
            title: '最后在线时间',
            dataIndex: 'node.lastSeenTime',
            key: 'node.lastSeenTime',
            align: 'center',
            render: (text, record, index)=> {
                return <span>{ moment(parseInt(record.node.lastSeenTime)).format("YYYY-MM-DD HH:mm:ss") }</span>
            }
        },
        {
            title: '备注',
            dataIndex: 'device.remarks',
            key: 'device.remarks',
            align: 'center',
            render: (text, record, index)=> {
                return <span>{StringUtils.emptyToSlash(record.device.remarks)}</span>
            }
        },
        {
            title: '操作',
            key: 'action',
            align: 'center',
            render: (text, record, index)=> {
                return <Space size="middle">
                    <a onClick={()=> viewDeviceDetails(record)}>查看详情</a>
                    <Popconfirm
                            title="删除设备"
                            description={ <div style={{paddingTop: "10px", paddingBottom: '10px'}}>此操作将删除设备及相关数据，删除的数据无法恢复，确认要删除么?</div> }
                            onConfirm={()=> onDeleteDevice(record)}
                            okText="确定"
                            cancelText="取消"
                        > 
                        <a>删除设备</a>
                    </Popconfirm>
                </Space>
            }
        }
    ];

    // effect
    useEffect(()=> {
        loadDeviceViews();
        loadDeviceTemplate();
        loadProductModels();
    }, []);

    useEffect(()=> {
        loadDeviceViews();
    }, [deviceTemplateId, productModel, search]);
    
    // render
    return (
        <DeviceManagementWrapper>
            {contextHolder}
            <ToolBarWrapper>
                <ToolBarLeftWrapper>
                    <Space size="middle">
                        <Button type="primary" onClick={onCreateDeviceClick}>新增设备</Button>
                        <Button type="primary" danger={true}>批量删除</Button>
                    </Space>
                </ToolBarLeftWrapper>

                <ToolBarRightWrapper>
                    <Space size="middle">
                        <FilterItem>
                            <FilterItemLabel>设备模板</FilterItemLabel>
                            <Select
                                allowClear
                                maxTagCount={2}
                                style={{ width: '150px' }}
                                placeholder="请选择设备模板"
                                value={deviceTemplateId}
                                onChange={(selectedValue)=> setDeviceTemplateId(selectedValue)}
                                options={deviceTemplateOptions}
                            />
                        </FilterItem>
                        <FilterItem>
                            <FilterItemLabel>设备型号</FilterItemLabel>
                            <Select
                                allowClear
                                maxTagCount={2}
                                style={{ width: '150px' }}
                                placeholder="请选择设备型号"
                                value={productModel}
                                onChange={(selectedValue)=> setProductModel(selectedValue)}
                                options={productModelOptions}
                            />
                        </FilterItem>
                        <FilterItem>
                            <FilterItemLabel>搜索</FilterItemLabel>
                            <Input 
                                placeholder="请输入关键字" 
                                suffix={<SearchOutlined />} 
                                size="middle" 
                                value={search} 
                                onChange={(e)=> setSearch(e.target.value)} 
                                onKeyDown={(e)=> onSearchKeyDown(e)}
                            />
                        </FilterItem>
                    </Space>
                </ToolBarRightWrapper>

                <Modal open={openCreateDeviceModal} 
                    onClose={()=> setOpenCreateDeviceModal(false)} 
                    footer={null} 
                    closable={false}
                    destroyOnClose={true}
                    width={1000}
                    height={600}
                    >
                    <CreateDeviceWizard onClose={()=> setOpenCreateDeviceModal(false)} onComplete={()=> loadDeviceViews()}/>
                </Modal>
            </ToolBarWrapper>
            <TableWrapper>
                <Table 
                    rowKey={(record)=> record.device.id}
                    columns={columns} 
                    dataSource={deviceViews}
                    pagination={tableParams.pagination}
                    loading={loading}
                    onChange={onTableChange}
                />
            </TableWrapper>

            <Routes>
                <Route path="device/:deviceId" element={<DeviceDetails onClose={()=>{}} onComplete={()=>{}}/>} />
            </Routes>
        </DeviceManagementWrapper>
    );
}

export default DeviceManagement;
