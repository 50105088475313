import { useEffect, useRef, useState } from "react";
import * as echarts from "echarts";
import { ECharts } from "echarts";
import moment from "moment";
import { Content, AlarmEvents, AlarmsOverview, TableHeader, TableHeaderRow, TableContent, DeviceName, EventTime, EventDetails, EventStatus, TableRow, AlarmOverviewBackgroundCircle, AlarmOverviewBackgroundLines, AlarmOverviewBackgroundRunning, AlarmOverviewBackground, AlarmOverviewChart, AlarmOverviewInfo, StatisticsItem, StatisticsIcon, StatisticsValue, StatisticsLabel, PercentValue, PercentSymbol, EChartWrapper } from "./DeviceAlarmStatistics.styled";
import { DeviceAlarmsWrapper } from "./DeviceAlarmStatistics.styled";
import AlarmEventApi from "../../../../../api/AlarmEventApi";
import AutoScrollUtils from "../../../../../util/AutoScrollUtils";

interface DeviceAlarmStatisticsProprs {

}

export const DeviceAlarmStatistics: React.FC<DeviceAlarmStatisticsProprs> = (props)=> {
    // api
    let alarmEventApi = new AlarmEventApi();
    
    // ref
    let carouselRef = useRef<any>();
    let echartsDomRef = useRef<HTMLElement>();
    let echartsRef = useRef<ECharts>(null);

    // state
    let [alarmEventStatistics, setAlarmEventStatistics] = useState({} as any);
    let [alarmEvents, setAlarmEvents] = useState([]);
    
    let initializeListCarousel = (dom)=> {
        if (dom) {
            carouselRef.current = AutoScrollUtils.addAutoScroll(dom);
        } else {
            if (carouselRef.current) {
                AutoScrollUtils.removeAutoScroll(carouselRef.current);
            }
        }
    };

    // init ECharts
    let initECharts = (dom: HTMLElement)=> {
        let echartsInstance = echarts.init(dom);
        echartsRef.current = echartsInstance;

        // init options
        let option = {
            tooltip: {
                show: false,
                trigger: 'item'
            },
            legend: {
                show: false,
            },
            grid: {
                x: 0,
                y: 0,
                x2: 0,
                y2: 0,
            },
            series: [
                {
                    name: '',
                    type: 'pie',
                    radius: ['85%', '77%'],
                    startAngle: 90,
                    padAngle: 10,
                    hoverAnimation: false,
                    itemStyle: {
                        borderWidth: 2,
                        borderColor: 'transparent',
                    },
                    label: {
                        show: false,
                    },
                    emphasis: {
                        label: {
                            show: false,
                            fontSize: 18,
                        }
                    },
                    labelLine: {
                        show: false,
                    },
                    data: [],
                },
            ]
        };
        echartsInstance.setOption(option);
    };
    
    let loadAlarmEventStatistics = async ()=> {
        let alarmEventStatistics = (await alarmEventApi.getStatistics()).data.data;
        let alarmEvents = (await alarmEventApi.queryViews({
            pageNo: 1,
            pageSize: 50,
        })).data.data.elements;
        setAlarmEventStatistics(alarmEventStatistics);
        setAlarmEvents(alarmEvents);

        echartsRef.current?.setOption({
            series: [
                {
                    data: [
                        {
                            name: '未处理', 
                            value: alarmEventStatistics.overview.unprocessed, 
                            itemStyle: {
                                normal: {
                                    color: 'rgba(255, 255, 255, 0.2)',
                                }
                            }, 
                        },
                        {
                            name: '已处理', 
                            value: alarmEventStatistics.overview.processed, 
                            itemStyle: {
                                normal: {
                                    color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                                        { offset: 0, color: 'rgba(251, 188, 88, 1.0)' },
                                        { offset: 1, color: 'rgba(233, 115, 42, 1.0)' },
                                    ]),
                                }
                            }, 
                        }
                    ]
                }
            ]
        });
    };

    // onload
    useEffect(()=> {
        let refreshTask = setInterval(()=> {
            loadAlarmEventStatistics();
        }, 5000);

        if (echartsDomRef.current!=null) {
            initECharts(echartsDomRef.current);
        }
        
        loadAlarmEventStatistics();

        return ()=> {
            clearInterval(refreshTask);
            if (echartsRef.current) {
                echartsRef.current.dispose();
            }
        }
    }, []);

    // render
    let formatEventTime = (timestamp: number | string)=> {
        timestamp = Number(timestamp);
        return moment(timestamp).format('YY/MM/DD HH:mm');
    };

    return (
        <DeviceAlarmsWrapper>
            <Content>
                <AlarmsOverview>
                    <AlarmOverviewBackground>
                        <AlarmOverviewBackgroundRunning />
                        <AlarmOverviewBackgroundLines />
                    </AlarmOverviewBackground>

                    <AlarmOverviewChart>
                        <AlarmOverviewInfo>
                            <StatisticsItem>
                                <StatisticsIcon />
                                <StatisticsValue>{alarmEventStatistics?.overview?.total}</StatisticsValue>
                                <StatisticsLabel>告警总数</StatisticsLabel>
                            </StatisticsItem>
                            
                            <StatisticsItem>
                                <StatisticsValue>
                                    <PercentValue>{alarmEventStatistics ? Math.round(alarmEventStatistics?.overview?.unprocessed / alarmEventStatistics?.overview?.total * 100) : 0}</PercentValue>
                                    <PercentSymbol>%</PercentSymbol>
                                </StatisticsValue>
                                <StatisticsLabel>未处理</StatisticsLabel>
                            </StatisticsItem>

                            <StatisticsItem>
                                <StatisticsIcon />
                                <StatisticsValue>{alarmEventStatistics?.overview?.today}</StatisticsValue>
                                <StatisticsLabel>今日新增</StatisticsLabel>
                            </StatisticsItem>
                        </AlarmOverviewInfo>

                        <EChartWrapper ref={(dom)=> {echartsDomRef.current=dom}}>
                        </EChartWrapper>
                    </AlarmOverviewChart>
                </AlarmsOverview>

                <AlarmEvents>
                    <TableHeader>
                        <TableHeaderRow>
                            <DeviceName>设备名称</DeviceName>
                            <EventTime>事件时间</EventTime>
                            <EventDetails>事件详情</EventDetails>
                            <EventStatus>事件状态</EventStatus>
                        </TableHeaderRow>
                    </TableHeader>

                    <TableContent ref={(dom)=> initializeListCarousel(dom)}>
                        {
                            alarmEvents.map((e)=> {
                                return <TableRow key={e.event.id}>
                                    <DeviceName>{e.device.name}</DeviceName>
                                    <EventTime className="value">{formatEventTime(parseInt(e.event.eventTime || e.event.startTime))}</EventTime>
                                    <EventDetails>{e.event.details}</EventDetails>
                                    <EventStatus style={{color: '#E70302'}}>未处理</EventStatus>
                                </TableRow>
                            })
                        }
                    </TableContent>
                </AlarmEvents>
            </Content>
        </DeviceAlarmsWrapper>
    )
};
