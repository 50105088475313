import styled from "styled-components";

export const BracketBorderCardWrapper = styled.div`
    position: relative;
    padding: 2px;
    box-sizing: border-box;
`;

export const BracketBorderLeftTop = styled.div`
    position: absolute;
    top: -1px;
    left: -1px;
    width: 20px;
    height: 20px;
    border-left: 3px solid rgba(91, 112, 104, 0.5);
    border-top: 3px solid rgba(91, 112, 104, 0.5);
`;

export const BracketBorderLeftBottom = styled.div`
    position: absolute;
    bottom: -1px;
    left: -1px;
    width: 20px;
    height: 20px;
    border-left: 3px solid rgba(91, 112, 104, 0.5);
    border-bottom: 3px solid rgba(91, 112, 104, 0.5);
`;

export const BracketBorderRightTop = styled.div`
    position: absolute;
    top: -1px;
    right: -1px;
    width: 20px;
    height: 20px;
    border-right: 3px solid rgba(91, 112, 104, 0.5);
    border-top: 3px solid rgba(91, 112, 104, 0.5);
`;

export const BracketBorderRightBottom = styled.div`
    position: absolute;
    bottom: -1px;
    right: -1px;
    width: 20px;
    height: 20px;
    border-right: 3px solid rgba(91, 112, 104, 0.5);
    border-bottom: 3px solid rgba(91, 112, 104, 0.5);
`;


