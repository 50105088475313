import { ReactElement, ReactNode, useEffect, useRef, useState } from "react";
import { CSSProperties } from "styled-components";
import { CSSTransition, SwitchTransition } from 'react-transition-group';
import { TransitionCarouselWrapper } from "./TransitionCarousel.styled";

import "./TransitionCarousel.css";

interface TransitionCarouselProps {
    style?: CSSProperties,
    children?: ReactNode[],
    switchInterval: number,
    switchOffset?: number,
}

let TransitionCarousel: React.FC<TransitionCarouselProps> = (props)=> {
    // ref
    let suspendRef = useRef(false);

    // state
    let [activeKey, setActiveKey] = useState((props.children[0] as ReactElement).key);
    let [activeIndex, setActiveIndex] = useState(0);

    // effect
    useEffect(()=> {
        let activeIndex = 0;
        let playTask = null;
        setTimeout(()=> {
            playTask = window.setInterval(()=> {
                if (suspendRef.current) {
                    return;
                }
                activeIndex = (activeIndex + 1) % props.children.length;
                setActiveIndex(activeIndex)
                setActiveKey((props.children[activeIndex] as ReactElement).key);
            }, props.switchInterval);
        }, props.switchOffset ?? 0);

        return ()=> {
            if (playTask != null) {
                window.clearInterval(playTask);
            }
        }
    }, []);

    // render
    return <TransitionCarouselWrapper style={props.style} onMouseOver={()=> suspendRef.current=true} onMouseOut={()=> suspendRef.current=false} onClick={()=> suspendRef.current=true}>
        <SwitchTransition>
            <CSSTransition classNames="scroll-x-fade-transition" timeout={300} key={activeKey}>
                {props.children[activeIndex]}
            </CSSTransition>
        </SwitchTransition>
    </TransitionCarouselWrapper>
};

export default TransitionCarousel;

