import React, { useEffect, useRef } from "react";
import EventManager from "./common/event-manager/EventManager";
import Navigator from "./components/navigator/Navigator";
import { DeivceOnlineDurationWrapper, DeviceAlarmStatisticsWrapper, DeviceAnalyseWrapper, DeviceListWrapper, DeviceMapWrapper, DeviceOnlineStatisticsWrapper, DeviceTrafficStatisticsWrapper, IntroductionVideoWrapper, OverviewBigScreenContent, OverviewBigScreenInnerWrapper, OverviewBigScreenWrapper, ScreenBorderBottom, ScreenBorderTop, ScreenTitleBar } from "./OverviewBigScreen.styled";
import { DeviceMap } from "./components/device-map/DeviceMap";
import { DeviceOnlineStatistics } from "./components/device-online-statistics/DeviceOnlineStatistics";
import { DeviceList } from "./components/device-list/DeviceList";
import { DeviceAlarmStatistics } from "./components/device-alarm-statistics/DeviceAlarmStatistics";
import DeviceNetworkTraffic from "./components/device-network-traffic/DeviceNetworkTraffix";
import DeviceOnlineDuration from "./components/device-online-duration/DeviceOnlineDuration";

import ScreenBorderTopBackground from "../assets/images/screen-border-top.webp";
import ScreenBorderBottomBackground from "../assets/images/screen-border-bottom.webp";
import BorderDecoratedCard from "./common/border-decorated-card/BorderDecoratedCard";
import DeviceAnalyse from "./components/device-analyse/DeviceAnalyse";
import TransitionCarousel from "./common/transition-carousel/TransitionCarousel";
import WeeklyDeviceUtilization from "./components/device-analyse/WeeklyDeviceUtilization";
import IntroductionVideo from "./components/introduction-video/IntroductionVideo";
import BracketBorderCard from "./common/bracket-border-card/BracketBorderCard";

interface OverviewBigScreenProps {
    
}

export const OverviewBigScreen: React.FC<OverviewBigScreenProps> = (props)=> {
    // event manager
    let eventManagerRef = useRef(new EventManager());

    // effect
    useEffect(()=> {
        let originalBackground = document.body.style.backgroundColor;
        document.body.style.backgroundColor = '#2A2A2A';
        return ()=> {
            document.body.style.backgroundColor = originalBackground;
        };
    }, []);

    // render
    return (
        <OverviewBigScreenWrapper>
            <OverviewBigScreenInnerWrapper>
                <ScreenTitleBar>
                    <Navigator selected="OVERVIEW"/>
                </ScreenTitleBar>

                <ScreenBorderTop src={ScreenBorderTopBackground} />

                <OverviewBigScreenContent>
                    <ScreenBorderBottom src={ScreenBorderBottomBackground} />

                    <IntroductionVideoWrapper>
                        <BorderDecoratedCard
                            titleCn="视频简介"
                            titleEn="INTRODUCTION VIDEO"
                            style={{width: '100%', height: '100%', paddingBottom: '0px'}}
                        >
                            <div style={{width: '100%', height: '100%', padding: '10px 15px 0px 15px', boxSizing: 'border-box'}}>
                                <BracketBorderCard style={{width: '100%', height: '100%'}}>
                                    <IntroductionVideo />
                                </BracketBorderCard>
                            </div>
                        </BorderDecoratedCard>
                    </IntroductionVideoWrapper>

                    <DeviceMapWrapper>
                        <DeviceMap eventManager={eventManagerRef.current} />
                    </DeviceMapWrapper>

                    <DeviceOnlineStatisticsWrapper>
                        <BorderDecoratedCard
                            titleCn="设备在线情况"
                            titleEn="ONLINE STATISTICS"
                            style={{width: '100%', height: '100%'}}
                        >
                            <DeviceOnlineStatistics />
                        </BorderDecoratedCard>
                    </DeviceOnlineStatisticsWrapper>

                    <DeviceListWrapper>
                        <BorderDecoratedCard
                            titleCn="设备列表"
                            titleEn="DEVICE LIST"
                            style={{width: '100%', height: '100%'}}
                        >
                            <DeviceList eventManager={eventManagerRef.current}/>
                        </BorderDecoratedCard>
                    </DeviceListWrapper>

                    <DeivceOnlineDurationWrapper>
                        <BorderDecoratedCard
                            titleCn=""
                            titleEn=""
                            style={{position: 'absolute', zIndex: '99', width: '100%', height: '100%'}}
                        >
                        </BorderDecoratedCard>

                        <TransitionCarousel switchInterval={10 * 1000} switchOffset={3000} style={{position: 'absolute', zIndex: '100', width: '100%', height: '100%'}}>
                            <BorderDecoratedCard
                                key="total-online-time"
                                titleCn="设备时长统计"
                                titleEn="DURATION STATISTICS"
                                titleBackgroundHide={true}
                                style={{width: '100%', height: '100%'}}
                            >
                                <DeviceOnlineDuration eventManager={eventManagerRef.current}/>
                            </BorderDecoratedCard>
                            <BorderDecoratedCard
                                key="today-online-time"
                                titleCn="今日在线时长"
                                titleEn="TODAY DURATION STATISTICS"
                                titleBackgroundHide={true}
                                style={{width: '100%', height: '100%'}}
                            >
                                <DeviceOnlineDuration eventManager={eventManagerRef.current} today={true}/>
                            </BorderDecoratedCard>
                        </TransitionCarousel>
                    </DeivceOnlineDurationWrapper>

                    <DeviceTrafficStatisticsWrapper>
                        <BorderDecoratedCard
                            titleCn="流量使用统计"
                            titleEn="NETWORK TRAFFIC USAGE"
                            style={{width: '100%', height: '100%'}}
                        >
                            <DeviceNetworkTraffic eventManager={eventManagerRef.current} />
                        </BorderDecoratedCard>
                    </DeviceTrafficStatisticsWrapper>

                    <DeviceAlarmStatisticsWrapper>
                        <BorderDecoratedCard
                                titleCn="告警事件"
                                titleEn="ALARMING EVENTS"
                                style={{width: '100%', height: '100%'}}
                            >
                            <DeviceAlarmStatistics />
                        </BorderDecoratedCard>
                    </DeviceAlarmStatisticsWrapper>

                    <DeviceAnalyseWrapper>
                        <BorderDecoratedCard
                                key="overall-analyse"
                                titleCn="数据统计分析"
                                titleEn="STATISTICS ANALYSE"
                                style={{width: '100%', height: '100%'}}
                            >
                            <DeviceAnalyse />
                        </BorderDecoratedCard>
                    </DeviceAnalyseWrapper>
                </OverviewBigScreenContent>

            </OverviewBigScreenInnerWrapper>

        </OverviewBigScreenWrapper>
    )
};
