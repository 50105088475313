import { memo, useEffect, useRef, useState } from "react";
import { ECharts } from "echarts";
import * as echarts from "echarts";
import StatisticsMetricApi from "../../../../../api/StatisticsMetricApi";
import { Content, DataAndTrendItem, DataAndTrendItemContent, DataAndTrendItemLabel, DataAndTrendWrapper, DataLabel, DataValue, DeviceAnalyseWrapper, EChartWrapper, GridBackgroundWrapper } from "./DeviceAnalyse.styled";
import moment from "moment";
import GridBackground from "../../common/grid-background/GridBackground";
import { ArrowDownOutlined, ArrowUpOutlined } from "@ant-design/icons";
import TransitionCarousel from "../../common/transition-carousel/TransitionCarousel";
import WeeklyDeviceUtilization from "./WeeklyDeviceUtilization";

interface DeviceAnalyseProprs {

}

let DeviceAnalyse: React.FC<DeviceAnalyseProprs> = (props)=> {
    // api
    let statisticsMetricApi = new StatisticsMetricApi();

    // state
    let [statisticsMetrics, setStatisticsMetrics] = useState([] as any[]);
    let [lastWeekStatisticsMetrics, setLastWeekStatisticsMetrics] = useState({online_device_count: 0, increase_device_count: 0, overall_device_utilization: 0} as any);
    let [thisWeekStatisticsMetrics, setThisWeekStatisticsMetrics] = useState({online_device_count: 0, increase_device_count: 0, overall_device_utilization: 0} as any);

    // load device analyse statistics metrics
    let loadDeviceAnalyseStatisticsMetrics = async ()=> {
        let timeNow = new Date();
        timeNow.setMilliseconds(0);
        timeNow.setSeconds(0);
        timeNow.setMinutes(0);
        timeNow.setHours(0);

        let day = timeNow.getDay();
        let thisWeekStartTime = timeNow.getTime() - day * 1000 * 3600 * 24;
        let thisWeekEndTime = timeNow.getTime() + (7-day) * 1000 * 3600 * 24;
        let lastWeekStartTime = thisWeekStartTime - 7 * 1000 * 3600 * 24;
        let prevWeekStartTime = lastWeekStartTime - 7 * 1000 * 3600 * 24;

        let metricNames = ['online_device_count', 'offline_device_count', 'increase_device_count', 'overall_device_utilization'];
        let metricNameTexts = ['在线设备数', '离线设备数', '新增设备数', '设备利用率'];
        let statisticsMetrics = (await statisticsMetricApi.query({
            objectIds: [''],
            metricNames: metricNames,
            timeScale: 'WEEK',
            startTime: prevWeekStartTime,
            endTime: thisWeekEndTime,
        })).data.data;
        setStatisticsMetrics(statisticsMetrics);

        let timeName2Metrics: Map<string, any[]> = new Map();
        for(let statisticsMetric of statisticsMetrics) {
            let timeName = statisticsMetric.statisticsTimeName;
            let metrics = timeName2Metrics.get(timeName);
            if (metrics == null) {
                metrics = [];
                timeName2Metrics.set(timeName, metrics);
            }
            metrics.push(statisticsMetric);
        }

        let timeNames = [moment(prevWeekStartTime).format('YYYY-MM-DD'), moment(lastWeekStartTime).format('YYYY-MM-DD'), moment(thisWeekStartTime).format('YYYY-MM-DD')];
        let timeNameTexts = ['前周', '上周', '本周'];
        for(let timeName of timeNames) {
            let metrics = timeName2Metrics.get(timeName);
            if (metrics == null) {
                metrics = [];
            }

            let timeNameText = timeNameTexts[timeNames.indexOf(timeName)];
            for(let metricName of metricNames) {
                let metricValue: number = null;
                for(let metric of metrics) {
                    if (metric.metricName == metricName) {
                        metricValue = Number(metric.metricValue);
                        break;
                    }
                }
                if (metricValue == null) {
                    metricValue = 1;
                }
                if (timeNameText == '本周') {
                    thisWeekStatisticsMetrics[metricName] = metricValue;
                } else if(timeNameText == '上周') {
                    lastWeekStatisticsMetrics[metricName] = metricValue;
                }
            }
        }
        setThisWeekStatisticsMetrics({...thisWeekStatisticsMetrics});
        setLastWeekStatisticsMetrics({...lastWeekStatisticsMetrics});
    };

    // onload
    useEffect(()=> {
        loadDeviceAnalyseStatisticsMetrics();

        let refreshTask = setInterval(()=> {
            loadDeviceAnalyseStatisticsMetrics();
        }, 1000 * 3600);

        return ()=> {
            clearInterval(refreshTask);
        }
    }, []);

    let getIncDecNumberValue = (value)=> {
        if (value > 0) {
            return <span><ArrowUpOutlined style={{fontSize: '12px'}}/> {value}</span>
        } else if(value < 0) {
            return <span><ArrowDownOutlined style={{fontSize: '12px'}} /> {value}</span>
        } else {
            return <span>{value}</span>
        }
    };

    // render
    return (
        <DeviceAnalyseWrapper>
            <Content>
                <GridBackgroundWrapper>
                    <GridBackground rows={20} cols={40} />
                </GridBackgroundWrapper>

                <TransitionCarousel switchInterval={10 * 1000} switchOffset={7000}>
                    <OverallAnalyze key="overall-analyse" statisticsMetrics={statisticsMetrics}/>
                    <WeeklyDeviceUtilization key="daily-device-utilization" />
                </TransitionCarousel>

                <DataAndTrendWrapper>
                    <DataAndTrendItem>
                        <DataAndTrendItemLabel>设备利用率 (%) </DataAndTrendItemLabel>
                        <DataAndTrendItemContent>
                            <DataLabel>本周</DataLabel>
                            <DataValue style={{color:'rgba(254, 200, 27, 1.0)'}}>{Math.round(thisWeekStatisticsMetrics.overall_device_utilization * 100.0).toFixed(0)}</DataValue>
                            <DataLabel>较上周</DataLabel>
                            <DataValue style={{color:'rgba(254, 200, 27, 1.0)'}}>{getIncDecNumberValue(Math.round((thisWeekStatisticsMetrics.overall_device_utilization - lastWeekStatisticsMetrics.overall_device_utilization) * 100.0))}</DataValue>
                        </DataAndTrendItemContent>
                    </DataAndTrendItem>

                    <DataAndTrendItem>
                        <DataAndTrendItemLabel>在线设备</DataAndTrendItemLabel>
                        <DataAndTrendItemContent>
                            <DataLabel>本周</DataLabel>
                            <DataValue style={{color:'rgba(1, 197, 249, 1.0)'}}>{thisWeekStatisticsMetrics.online_device_count}</DataValue>
                            <DataLabel>较上周</DataLabel>
                            <DataValue style={{color:'rgba(1, 197, 249, 1.0)'}}>{getIncDecNumberValue(thisWeekStatisticsMetrics.online_device_count - lastWeekStatisticsMetrics.online_device_count)}</DataValue>
                        </DataAndTrendItemContent>
                    </DataAndTrendItem>

                    <DataAndTrendItem>
                        <DataAndTrendItemLabel>新增设备</DataAndTrendItemLabel>
                        <DataAndTrendItemContent>
                            <DataLabel>本周</DataLabel>
                            <DataValue style={{color:'rgba(255, 87, 51, 1.0)'}}>{thisWeekStatisticsMetrics.increase_device_count}</DataValue>
                            <DataLabel>较上周</DataLabel>
                            <DataValue style={{color:'rgba(255, 87, 51, 1.0)'}}>{getIncDecNumberValue(thisWeekStatisticsMetrics.increase_device_count - lastWeekStatisticsMetrics.increase_device_count)}</DataValue>
                        </DataAndTrendItemContent>
                    </DataAndTrendItem>

                </DataAndTrendWrapper>
            </Content>
        </DeviceAnalyseWrapper>
    )
};


interface OverallAnalyzeProps {
    statisticsMetrics: any[]
}

let OverallAnalyze: React.FC<OverallAnalyzeProps> = (props)=> {
    // ref
    let echartsDomRef = useRef<HTMLElement>();
    let echartsRef = useRef<ECharts>(null);
    let dataMaxExceptUtilizationRef = useRef(100);
    let statisticsMetricsRef = useRef(null as any);
    
    // init ECharts
    let initECharts = (dom: HTMLElement)=> {
        if (dom == null) {
            echartsRef.current?.dispose();
            echartsRef.current = null;
            echartsDomRef.current = null;
            return;
        }
        let echartsInstance = echarts.init(dom);
        echartsRef.current = echartsInstance;
        echartsDomRef.current = dom;

        // init options
        let option = {
            tooltip: {
                show: true,
                trigger: 'item',
                borderWidth: 1,
                borderColor: 'rgba(255, 255, 255, 0.2)',
                backgroundColor: 'rgba(0, 0 , 0, 0.25)',
                textStyle: {
                    color: '#FFFFFF',
                    fontStyle: 'normal',
                },
                formatter: function(params) {
                    if (params == null) {
                        return '';
                    }
                    if (!Array.isArray(params)) {
                        params = [params];
                    }

                    let seriesContent = '';
                    for(let param of params) {
                        if (param.value==null) {
                            continue;
                        }
                        
                        seriesContent += `
                            <div style="">
                                <div style="margin: 0px 0px 5px 0px;">
                                    <div style="display: inline-block">${param.seriesName}</div>
                                </div>
                                <div>
                                    <span style="display: inline-block">${param.name}</span>
                                    <span style="display: inline-block; font-size: 24px; font-weight: bold">${param.name == '设备利用率' ? Math.round(param.value / dataMaxExceptUtilizationRef.current * 100.0) + '%' : param.value}</span>
                                </div>
                            </div>
                        `;
                        break;
                    }
                    let content=`
                        <div>
                            ${seriesContent}
                        </div>
                    `;
                    return content;
                },
            },
            legend: {
                show: true,
                orient: 'vertical',
                left: 30,
                bottom: 0,
                icon: 'rect',
                textStyle: {
                    color: '#FFFFFF',
                    fontSize: 11,
                    fontStyle: 'normal',
                }
            },
            grid: {
                x: 200,
                y: 20,
                x2: 0,
                y2: 20,
            },
            xAxis: [
                {
                    type: 'category',
                    data: [],
                    axisTick: {
                        show: false,
                    },
                    axisLine: {
                        lineStyle: {
                            width: 1,
                            color: '#FFFFFF',
                            shadowColor: '#CCCCCC',
                        } 
                    },
                    axisLabel: {
                        show: true,
                        position: 'top',
                        fontStyle: 'normal',
                        color: '#FFFFFF',
                    }
                }
            ],
            yAxis: [
                {
                    type: 'value',
                    show: false,
                },
            ],
            series: []
        };
        echartsInstance.setOption(option);

        if (props.statisticsMetrics != null && props.statisticsMetrics.length > 0) {
            onStatisticsMetricsChange(props.statisticsMetrics);
        }
    };

    let onStatisticsMetricsChange = async (statisticsMetrics: any)=> {
        let timeNow = new Date();
        timeNow.setMilliseconds(0);
        timeNow.setSeconds(0);
        timeNow.setMinutes(0);
        timeNow.setHours(0);

        let day = timeNow.getDay();
        let thisWeekStartTime = timeNow.getTime() - day * 1000 * 3600 * 24;
        let thisWeekEndTime = timeNow.getTime() + (7-day) * 1000 * 3600 * 24;
        let lastWeekStartTime = thisWeekStartTime - 7 * 1000 * 3600 * 24;
        let prevWeekStartTime = lastWeekStartTime - 7 * 1000 * 3600 * 24;

        let metricNames = ['online_device_count', 'offline_device_count', 'increase_device_count', 'overall_device_utilization'];
        let metricNameTexts = ['在线设备数', '离线设备数', '新增设备数', '设备利用率'];

        let dataMaxExceptUtilization = 0;
        let timeName2Metrics: Map<string, any[]> = new Map();
        for(let statisticsMetric of statisticsMetrics) {
            let timeName = statisticsMetric.statisticsTimeName;
            let metrics = timeName2Metrics.get(timeName);
            if (metrics == null) {
                metrics = [];
                timeName2Metrics.set(timeName, metrics);
            }
            metrics.push(statisticsMetric);
            if (statisticsMetric.metricName != 'overall_device_utilization') {
                dataMaxExceptUtilization = Math.max(dataMaxExceptUtilization, Number(statisticsMetric.metricValue));
            }
        }
        dataMaxExceptUtilizationRef.current = dataMaxExceptUtilization;

        let seriesItemColors = [
            new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                { offset: 0, color: 'rgba(255, 87, 51, 1.0)' },
                { offset: 0.5, color: 'rgba(255, 87, 51, 1.0)' },
                { offset: 1, color: 'rgba(255, 87, 51, 0.3)' }
            ]),
            new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                { offset: 0, color: 'rgba(1, 197, 249, 1.0)' },
                { offset: 0.5, color: 'rgba(1, 197, 249, 1.0)' },
                { offset: 1, color: 'rgba(1, 197, 249, 0.3)' }
            ]),
            new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                { offset: 0, color: 'rgba(254, 200, 27, 1.0)' },
                { offset: 0.5, color: 'rgba(254, 200, 27, 1.0)' },
                { offset: 1, color: 'rgba(254, 200, 27, 0.3)' }
            ]),
            new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                { offset: 0, color: 'rgba(90, 105, 255, 1.0)' },
                { offset: 0.5, color: 'rgba(90, 105, 255, 1.0)' },
                { offset: 1, color: 'rgba(90, 105, 255, 0.3)' }
            ]),
        ];

        let seriesArr = [];
        let timeNames = [moment(prevWeekStartTime).format('YYYY-MM-DD'), moment(lastWeekStartTime).format('YYYY-MM-DD'), moment(thisWeekStartTime).format('YYYY-MM-DD')];
        let timeNameTexts = ['前周', '上周', '本周'];
        for(let timeName of timeNames) {
            let metrics = timeName2Metrics.get(timeName);
            if (metrics == null) {
                metrics = [];
            }

            let timeNameText = timeNameTexts[timeNames.indexOf(timeName)];
            let series = {
                name: `${timeNameText}(${timeName})`,
                type: 'bar',
                barWidth: 20,
                barGap: 0.1,
                emphasis: {
                    focus: 'series'
                },
                itemStyle: {
                    color: seriesItemColors[seriesArr.length % seriesItemColors.length],
                },
                label: {
                    show: true,
                    position: 'top',
                    fontStyle: 'normal',
                    color: '#FFFFFF',
                    formatter: (param)=> {
                        if(param.name == '设备利用率') {
                            return Math.round(param.value / dataMaxExceptUtilization * 100.0) + '';
                        } else {
                            return param.value;
                        }
                    },
                },
                data: [],
            };
            seriesArr.push(series);

            for(let metricName of metricNames) {
                let metricValue: number = null;
                for(let metric of metrics) {
                    if (metric.metricName == metricName) {
                        metricValue = Number(metric.metricValue);
                        break;
                    }
                }
                if (metricValue == null) {
                    metricValue = 1;
                }

                if (metricName == 'overall_device_utilization') {
                    metricValue = metricValue * dataMaxExceptUtilization;
                }
                series.data.push(metricValue);
            }
        };
        
        // refresh echarts options
        echartsRef.current?.setOption({
            xAxis: [
                {data: metricNameTexts}
            ],
            series: seriesArr,
        });
    };
    
    // onload
    useEffect(()=> {
        return ()=> {
            if(echartsRef.current!=null) {
                echartsRef.current.dispose();
                echartsRef.current = null;
                echartsDomRef.current = null;
            }
        };
    }, []);

    // render
    return <EChartWrapper ref={(dom)=> {initECharts(dom)}}>
    </EChartWrapper>
};

export default DeviceAnalyse;
