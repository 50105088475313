

class KeyCodeUtils {
    public static BACKSPACE=8;
    public static TAB=9;
    public static ENTER=13;
    public static ESC=27;
    public static LEFT=37;
    public static UP=38;
    public static RIGHT=39;
    public static DOWN=40;
    public static DELETE=46;
    public static S=83;
    public static F2=113;
}

export default KeyCodeUtils;