import { useEffect, useState } from "react";
import { CheckCircleFilled, CloseCircleFilled, EditOutlined, PoweroffOutlined } from "@ant-design/icons";
import { useSearchParams } from "react-router-dom";
import { Amap, CircleMarker } from "@amap/amap-react";
import { Button, Modal, Popconfirm, message } from "antd";
import moment from "moment";
import DeviceApi from "../../../../../../api/DeviceApi";
import StringUtils from "../../../../../../util/StringUtils";
import { DeviceInformationWrapper, DeviceLocationMapWrapper, InfoColumn, InfoGroup, InfoGroupContent, InfoGroupSeparator, InfoGroupTitle, InfoItem, InfoLabel, InfoValue, ProductModelPicture } from "./DeviceInformation.styled";
import { DeviceStatusUtils } from "../../../../../../components/ui-components/device-status/DeviceStatus";
import UpdateDeviceInformation from "./update-device-information/UpdateDeviceInformation";
import WLS20_Picture from '../../../../../../assets/model-pictures/wls20/wls20.jpg';

interface DeviceInformationProps {
    deviceView: any
}

export const DeviceInformation: React.FC<DeviceInformationProps> = (props)=> {
    // params
    let latestHeartBeatMetric = props.deviceView.latestHeartBeatMetric;
    let mapCenter = latestHeartBeatMetric == null ? [116, 39]: [latestHeartBeatMetric.longitude, latestHeartBeatMetric.latitude];
    let [ searchParams ] = useSearchParams();

    // api
    const [messageApi, contextHolder] = message.useMessage();
    let deviceApi = new DeviceApi();

    // state
    let [openUpdateDeviceInformationModal, setOpenUpdateDeviceInformationModal] = useState(false);
    let [deviceView, setDeviceView] = useState(props.deviceView);

    // events
    let refreshDeviceView = async ()=> {
        let newDeviceView = (await deviceApi.getDeviceViewByDeviceId(deviceView.device.id)).data.data;
        setDeviceView(newDeviceView);
    };

    let onEditDeviceInformation = ()=> {
        setOpenUpdateDeviceInformationModal(true);
    };

    let onReboot = async ()=> {
        let responseData = (await deviceApi.invokeService(deviceView.device.id, 'reboot', {})).data.data;
        messageApi.open({
            type: 'success',
            content: '发送命令成功',
        });
    };

    // utils
    let formatDateTime = (timestamp: number | string)=> {
        timestamp = Number(timestamp);
        return moment(timestamp).format('YYYY/MM/DD HH:mm:ss');
    }

    // render
    return (
        <DeviceInformationWrapper>
            {contextHolder}
            <InfoGroup>
                <InfoGroupTitle>设备信息 <Button type='link' onClick={onEditDeviceInformation}><EditOutlined />编辑</Button></InfoGroupTitle>
                <InfoGroupContent>
                    <InfoColumn>
                        <ProductModelPicture style={{
                            backgroundImage: `url(${WLS20_Picture})`
                        }} />

                        <InfoItem>
                            <InfoLabel></InfoLabel>
                            <InfoValue>
                            <Popconfirm
                                    title="重启设备"
                                    description={ <div style={{paddingTop: "10px", paddingBottom: '10px'}}>确认重启该设备么?</div> }
                                    onConfirm={()=> onReboot()}
                                    okText="确定"
                                    cancelText="取消"
                                > 
                                {searchParams.get('reboot') && <Button type='link' disabled={deviceView.node.status == 'OFFLINE'} style={{marginTop: '10px'}}><PoweroffOutlined />重启设备</Button> }
                            </Popconfirm>
                            </InfoValue>
                        </InfoItem>
                    </InfoColumn>

                    <InfoColumn>
                        <InfoItem>
                            <InfoLabel>设备状态</InfoLabel>
                            {
                                deviceView.node.status == 'ONLINE'
                                ? <InfoValue><CheckCircleFilled style={{color: 'green', marginRight: '10px'}}/>在线</InfoValue>
                                : <InfoValue><CloseCircleFilled style={{color: 'grey', marginRight: '10px'}} />离线</InfoValue>
                            }
                        </InfoItem>
                        <InfoItem>
                            <InfoLabel>信号强度</InfoLabel>
                            <InfoValue>{deviceView.latestHeartBeatMetric.rssi}dbm</InfoValue>
                        </InfoItem>
                        <InfoItem>
                            <InfoLabel>最后在线时间 (yyyy/MM/dd)</InfoLabel>
                            <InfoValue>{formatDateTime(deviceView.latestHeartBeatMetric.timestamp)}</InfoValue>
                        </InfoItem>
                    </InfoColumn>

                    <InfoColumn>
                        <InfoItem>
                            <InfoLabel>设备名称</InfoLabel>
                            <InfoValue>{deviceView.device.name}</InfoValue>
                        </InfoItem>
                        <InfoItem>
                            <InfoLabel>设备编号</InfoLabel>
                            <InfoValue>{deviceView.device.deviceNo}</InfoValue>
                        </InfoItem>
                        <InfoItem>
                            <InfoLabel>设备模板</InfoLabel>
                            <InfoValue>{deviceView.deviceTemplate.name}</InfoValue>
                        </InfoItem>
                        <InfoItem>
                            <InfoLabel>产品型号</InfoLabel>
                            <InfoValue>{deviceView.node.model}</InfoValue>
                        </InfoItem>
                        <InfoItem>
                            <InfoLabel>备注</InfoLabel>
                            <InfoValue>{StringUtils.emptyToSlash(deviceView.device.remarks)}</InfoValue>
                        </InfoItem>
                    </InfoColumn>

                    <InfoColumn>
                        <InfoItem>
                            <InfoLabel>序列号 SN</InfoLabel>
                            <InfoValue>{deviceView.node.sn}</InfoValue>
                        </InfoItem>
                        <InfoItem>
                            <InfoLabel>固件版本</InfoLabel>
                            <InfoValue>Ver {deviceView.node.firmwareVersion}</InfoValue>
                        </InfoItem>
                        <InfoItem>
                            <InfoLabel>IMEI</InfoLabel>
                            <InfoValue>{deviceView.node.imei}</InfoValue>
                        </InfoItem>
                        <InfoItem>
                            <InfoLabel>ICCID</InfoLabel>
                            <InfoValue>{deviceView.node.iccid}</InfoValue>
                        </InfoItem>
                        <InfoItem>
                            <InfoLabel>运营商</InfoLabel>
                            <InfoValue>{deviceView.node.oper}</InfoValue>
                        </InfoItem>
                    </InfoColumn>
                </InfoGroupContent>
            </InfoGroup>
            
            <InfoGroupSeparator />

            <InfoGroup>
                <InfoGroupTitle>设备定位</InfoGroupTitle>
                <InfoGroupContent>
                    <InfoColumn style={{width: 'auto', marginRight: '50px'}}>
                        <DeviceLocationMapWrapper>
                            <Amap
                                mapStyle='amap://styles/dark'
                                zoom={4}
                                center={mapCenter}>
                                
                                {
                                    latestHeartBeatMetric && (
                                        <CircleMarker
                                            center={mapCenter} 
                                            radius={6} 
                                            fillColor={DeviceStatusUtils.getDeviceMarkerColor(deviceView.node.status)}
                                            fillOpacity={0.8} 
                                            strokeWeight={0}
                                            cursor="pointer" />
                                    )
                                }
                            </Amap>
                        </DeviceLocationMapWrapper>
                    </InfoColumn>
                    <InfoColumn>
                        <InfoItem>
                            <InfoLabel>定位方式</InfoLabel>
                            <InfoValue>{latestHeartBeatMetric?.altitude == null ? '基站定位': 'GNSS'}</InfoValue>
                        </InfoItem>
                        <InfoItem>
                            <InfoLabel>最后位置时间</InfoLabel>
                            <InfoValue>{latestHeartBeatMetric==null ? '未知' : formatDateTime(latestHeartBeatMetric.timestamp)}</InfoValue>
                        </InfoItem>
                        <InfoItem>
                            <InfoLabel>经度</InfoLabel>
                            <InfoValue>{latestHeartBeatMetric?.longitude?.toFixed(6) ?? 'N/A'}</InfoValue>
                        </InfoItem>
                        <InfoItem>
                            <InfoLabel>纬度</InfoLabel>
                            <InfoValue>{latestHeartBeatMetric?.latitude?.toFixed(6) ?? 'N/A'}</InfoValue>
                        </InfoItem>
                        <InfoItem>
                            <InfoLabel>高度</InfoLabel>
                            <InfoValue>{latestHeartBeatMetric?.altitude?.toFixed(6) ?? 'N/A'}</InfoValue>
                        </InfoItem>
                    </InfoColumn>
                    <InfoColumn>
                        <InfoItem>
                            <InfoLabel>速度 (km/h)</InfoLabel>
                            <InfoValue>{latestHeartBeatMetric?.speed_km?.toFixed(0) ?? 'N/A'}</InfoValue>
                        </InfoItem>
                        <InfoItem>
                            <InfoLabel>方向</InfoLabel>
                            <InfoValue>{latestHeartBeatMetric?.course?.toFixed(2) ?? 'N/A'}</InfoValue>
                        </InfoItem>
                        <InfoItem>
                            <InfoLabel>卫星数量</InfoLabel>
                            <InfoValue>{latestHeartBeatMetric?.satelites?.toFixed(2) ?? 'N/A'}</InfoValue>
                        </InfoItem>
                        <InfoItem>
                            <InfoLabel>LAC</InfoLabel>
                            <InfoValue>{latestHeartBeatMetric?.lac ?? 'N/A'}</InfoValue>
                        </InfoItem>
                        <InfoItem>
                            <InfoLabel>CI</InfoLabel>
                            <InfoValue>{latestHeartBeatMetric?.ci ?? 'N/A'}</InfoValue>
                        </InfoItem>
                    </InfoColumn>
                </InfoGroupContent>
            </InfoGroup>

            <Modal open={openUpdateDeviceInformationModal} 
                onClose={()=> setOpenUpdateDeviceInformationModal(false)} 
                footer={null} 
                closable={false}
                destroyOnClose={true}
                width={1000}
                >
                <UpdateDeviceInformation
                    deviceView={deviceView}
                    onClose={()=> setOpenUpdateDeviceInformationModal(false)} 
                    onComplete={()=> refreshDeviceView()}/>
            </Modal>
        </DeviceInformationWrapper>
    )
}

export default DeviceInformation;
