
import styled from "styled-components";
import BackgroundCircleImage from './assets/background-circle.png';
import BackgroundRunningImage from './assets/background-running.png';
import BackgroundLinesImage from './assets/background-lines.png';

export const DeviceAlarmsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: stretch;
    padding: 10px;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
`;

export const Content = styled.div`
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    padding: 10px 10px;
`;

export const AlarmsOverview = styled.div`
    position: relative;
`;

export const AlarmOverviewBackground = styled.div`
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
`;

export const AlarmOverviewBackgroundCircle = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    background: url(${BackgroundCircleImage});
    background-size: 180px;
    background-position: center center;
    background-repeat: no-repeat;
`;

export const AlarmOverviewBackgroundRunning = styled.div`
    position: absolute;
    top: -2px;
    width: 100%;
    height: 100%;
    background: url(${BackgroundRunningImage});
    background-size: 130px;
    background-position: center center;
    background-repeat: no-repeat;
    animation: alarm-overview-background-rotate infinite 5s;
    transform-origin: center center;

    @keyframes alarm-overview-background-rotate {
        0% {
            transform: rotate(0deg);
        }
        50% {
            transform: rotate(180deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
`;

export const AlarmOverviewBackgroundLines = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    background: url(${BackgroundLinesImage});
    background-size: auto;
    background-position: center center;
    background-repeat: no-repeat;
`;

export const AlarmOverviewChart = styled.div`
    height: 150px;
    display: flex;
    flex-direction: row;
    justify-content: center;
`;

export const AlarmOverviewInfo = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
`;

export const StatisticsItem = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100px;
`;

export const StatisticsIcon = styled.div`
    display: inline-block;
    width: 0px;
    height: 0px;
    border-top: 10px solid rgba(235, 155, 67);
    border-right: 8px solid transparent;
    border-bottom: none;
    border-left: 8px solid transparent;
`;

export const StatisticsValue = styled.div`
    font-size: 32px;
    font-weight: bold;
    color: rgba(235, 155, 67);
    margin: 5px 0px 3px 0px;
    padding-left: 2px;
`;

export const StatisticsLabel = styled.div`

`;

export const PercentValue = styled.span`
    font-size: 28px;
    font-weight: bold;
`;

export const PercentSymbol = styled.span`
    font-size: 22px;
    font-family: Rajdhani;
`;

export const EChartWrapper = styled.div`
    width: 180px;
    height: 100%;
    position: relative;
    top: -3px;
`;

export const AlarmEvents = styled.div`
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    padding-top: 10px;
`;

export const TableHeader = styled.div`
    display: flex;
    flex-direction: column;
    align-items: stretch;
    padding-bottom: 5px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    margin-bottom: 5px;
`;

export const TableContent = styled.div`
    display: flex;
    flex-direction: column;
    align-items: stretch;
`;

export const TableHeaderRow = styled.div`
    display: grid;
    width: 100%;
    grid-template-columns: 1fr 1.2fr 2fr 0.8fr;
    grid-gap: 5px;
    align-items: center;
    justify-items: stretch;
    background-color: rgba(255, 255, 255, 0.05);
    font-size: 12px;
    font-style: normal;
    
    & div {
        padding-top: 3px;
        padding-bottom: 3px;
        background-color: rgba(255, 255, 255, 0.1);
    }
`;

export const TableRow = styled.div`
    display: grid;
    width: 100%;
    grid-template-columns: 1fr 1.2fr 2fr 0.8fr;
    grid-gap: 5px;
    align-items: center;
    justify-items: stretch;
    font-size: 12px;
    font-style: normal;
    padding: 5px 0px;
    cursor: pointer;
    position: relative;

    &::after {
        content: '';
        position: absolute;
        height: 1px;
        width: 100%;
        bottom: 0px;
        background: linear-gradient(90deg, rgba(255, 255, 255, 0.025) 0%, rgba(255, 255, 255, 0.075) 50%, rgba(255, 255, 255, 0.025) 100%);
    }

    &:nth-child(even) {
        
    }
    
    &:hover {
        background: linear-gradient(0deg, rgba(55, 72, 108, 0.5) 0%, rgba(55, 72, 108, 0.0) 50%, rgba(55, 72, 108, 0.5) 100%);
    }

    &:hover:nth-child(even) {
        background: linear-gradient(0deg, rgba(55, 72, 108, 0.5) 0%, rgba(55, 72, 108, 0.0) 50%, rgba(55, 72, 108, 0.5) 100%);
    }

`;

export const DeviceName = styled.div`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 0px 5px;
`;

export const EventTime = styled.div`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;
    box-sizing: border-box;

    &.value {
        padding-top: 2px;
        padding-bottom: 2px;
    }
`;

export const EventDetails = styled.div`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;
`;

export const EventStatus = styled.div`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: flex;
    justify-content: center;
`;


