import styled from "styled-components"


export const DeviceAnalyseWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: stretch;
    padding: 10px;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    letter-spacing: 1px;
    font-family: -apple-system, "SF UI Text", "Helvetica Neue", Arial, "PingFang SC", "Hiragino Sans GB", "Source Han Sans CN", "Microsoft YaHei", "WenQuanYi Zen Hei", sans-serif;
    font-size: 12px;
    color: #FFFFFF;
`;

export const Content = styled.div`
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    position: relative;
    overflow-y: hidden;
`;

export const GridBackgroundWrapper = styled.div`
    position: absolute;
    width: calc(100% - 200px);
    height: 100%;
    right: 0px;
    z-index: 99;
`;

export const EChartWrapper = styled.div`
    width: 100%;
    height: 100%;
    z-index: 100;
`;

export const DataAndTrendWrapper = styled.div`
    position: absolute;
    height: 100%;
    width: 200px;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    box-sizing: border-box;
    font-style: normal;

    &::after {
        content: '';
        position: absolute;
        height: 100%;
        width: 1px;
        right: -1px;
        background: linear-gradient(0deg, rgba(255, 255, 255, 0.05) 0%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.05) 100%);
    }
`;

export const DataAndTrendItem = styled.div`
    display: flex;
    flex-direction: column;
    align-items: stretch;
    width: 100%;
    padding: 5px 0px;
    box-sizing: border-box;

    &::after {
        content: '';
        height: 1px;
        width: 100%;
        margin-top: 5px;
        background: linear-gradient(90deg, rgba(255, 255, 255, 0.05) 0%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.05) 100%);
    }
`;

export const DataAndTrendItemLabel = styled.div`
    padding: 5px 0px 0px 0px;
    text-align: center;
    color: rgba(255, 255, 255, 0.9);
`;

export const DataAndTrendItemContent = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: center;
    flex-wrap: nowrap;
`;

export const DataLabel = styled.div`
    font-size: 11px;
    color: rgba(255, 255, 255, 0.5);
    white-space: nowrap;
`;

export const DataValue = styled.div`
    font-size: 20px;
    font-weight: bold;
    line-height: 1.0;
    padding: 0px 3px;
    position: relative;
    top: 2px;
    white-space: nowrap;
`;
