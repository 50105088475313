import { IntroductionVideoWrapper } from "./IntroductionVideo.styled";

interface IntroductionVideoProps {

}

let IntroductionVideo: React.FC<IntroductionVideoProps> = (props)=> {
    return <IntroductionVideoWrapper>
            <video width="100%" height="100%" controls muted playsInline={true} loop autoPlay={true} dangerouslySetInnerHTML={{__html: `
                <source src="/static/video/introduction-video.mp4" type="video/mp4">
                    您的浏览器不支持视频标签。
                </source>
            `}}>
            </video>
    </IntroductionVideoWrapper>
};

export default IntroductionVideo;
