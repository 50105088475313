import { memo, useEffect, useRef } from "react";
import { ECharts } from "echarts";
import * as echarts from "echarts";
import StatisticsMetricApi from "../../../../../api/StatisticsMetricApi";
import { Content, DeviceAnalyseWrapper, EChartWrapper, GridBackgroundWrapper } from "./DeviceAnalyse.styled";
import GridBackground from "../../common/grid-background/GridBackground";
import DeviceApi from "../../../../../api/DeviceApi";
import moment from "moment";

interface WeeklyDeviceUtilizationProps {

}

export const WeeklyDeviceUtilization: React.FC<WeeklyDeviceUtilizationProps> = memo((props)=> {
    // api
    let deviceApi = new DeviceApi();
    let statisticsMetricApi = new StatisticsMetricApi();

    // ref
    let echartsDomRef = useRef<HTMLElement>();
    let echartsRef = useRef<ECharts>(null);

    // key: device, value: series data
    let deviceUtilizationMetricsMapRef = useRef(new Map<any, any>());
    
    // init ECharts
    let initECharts = (dom: HTMLElement)=> {
        let echartsInstance = echarts.init(dom);
        echartsRef.current = echartsInstance;

        // init options
        let option = {
            tooltip: {
                show: true,
                trigger: 'item',
                borderWidth: 1,
                borderColor: 'rgba(255, 255, 255, 0.2)',
                backgroundColor: 'rgba(0, 0 , 0, 0.25)',
                textStyle: {
                    color: '#FFFFFF',
                    fontStyle: 'italic',
                },
                formatter: function(params) {
                    if (params == null) {
                        return '';
                    }
                    if (!Array.isArray(params)) {
                        params = [params];
                    }

                    let seriesContent = '';
                    for(let param of params) {
                        if (param.value==null) {
                            continue;
                        }
                        
                        seriesContent += `
                            <div style="">
                                <div style="margin: 0px 0px 5px 0px;">
                                    <div style="display: inline-block">${param.seriesName}</div>
                                </div>
                                <div>
                                    <span style="display: inline-block">${param.name}</span>
                                    <span style="display: inline-block; font-size: 24px; font-weight: bold">${param.value + '%'}</span>
                                </div>
                            </div>
                        `;
                        break;
                    }
                    let content=`
                        <div>
                            ${seriesContent}
                        </div>
                    `;
                    return content;
                },
            },
            legend: {
                show: true,
                orient: 'vertical',
                left: 30,
                bottom: 0,
                icon: 'rect',
                textStyle: {
                    color: '#FFFFFF',
                    fontSize: 11,
                    fontStyle: 'italic',
                }
            },
            grid: {
                x: 230,
                y: 20,
                x2: 0,
                y2: 20,
            },
            xAxis: [
                {
                    type: 'category',
                    data: [],
                    axisTick: {
                        show: false,
                    },
                    axisLine: {
                        lineStyle: {
                            width: 1,
                            color: '#FFFFFF',
                            shadowColor: '#CCCCCC',
                        } 
                    },
                    axisLabel: {
                        show: true,
                        position: 'top',
                        fontStyle: 'italic',
                        color: '#FFFFFF',
                    },
                }
            ],
            yAxis: [
                {
                    type: 'value',
                    axisTick: {
                        show: false,
                    },
                    axisLine: {
                        show: false,
                    },
                    axisLabel: {
                        show: true,
                        position: 'top',
                        fontStyle: 'italic',
                        color: '#FFFFFF',
                    },
                    splitLine: {
                        show: true,
                        lineStyle: {
                            color: 'rgba(255, 255, 255, 0.2)',
                            width: 1,
                            type: 'solid',
                        },
                    },
                    splitNumber: 4,
                    minorTick: {
                        show: false,
                    },
                    dataMin: 0,
                    dataMax: 100,
                },
            ],
            series: []
        };
        echartsInstance.setOption(option);
    };

    // load overall device utilization statistics metrics
    let loadWeeklyDeviceUtilizationStatisticsMetrics = async ()=> {
        let timeNow = new Date();
        timeNow.setMilliseconds(0);
        timeNow.setSeconds(0);
        timeNow.setMinutes(0);
        timeNow.setHours(0);

        let day = timeNow.getDay();
        let thisWeekStartTime = timeNow.getTime() - day * 1000 * 3600 * 24;
        let thisWeekEndTime = timeNow.getTime() + (7-day) * 1000 * 3600 * 24;
        let lastWeekStartTime = thisWeekStartTime - 7 * 1000 * 3600 * 24;
        let prev8WeekStartTime = lastWeekStartTime - 8 * 7 * 1000 * 3600 * 24;

        let metricNames = ['overall_device_utilization'];
        let statisticsMetrics = (await statisticsMetricApi.query({
            objectIds: [''],
            metricNames: metricNames,
            timeScale: 'WEEK',
            startTime: prev8WeekStartTime,
            endTime: thisWeekEndTime,
        })).data.data;
        deviceUtilizationMetricsMapRef.current.set({name: '设备总体利用率'}, statisticsMetrics);
        
        let seriesItemColors = [
            new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                { offset: 0, color: 'rgba(1, 197, 249, 1.0)' },
                { offset: 0.5, color: 'rgba(1, 197, 249, 1.0)' },
                { offset: 1, color: 'rgba(1, 197, 249, 0.3)' }
            ]),
        ];

        let seriesArr = [];
        let timeNames = [];
        let metricValues = [];

        for(let i=16; i>=0; i--) {
            let weekTime = thisWeekStartTime - i * 7 * 1000 * 3600 * 24;
            let weekTimeName = moment(weekTime).format('YYYY-MM-DD');
            timeNames.push(weekTimeName);

            let foundMetricValue = false;
            for(let statisticsMetric of statisticsMetrics) {
                let timeName = statisticsMetric.statisticsTimeName;
                if (timeName == weekTimeName) {
                    let metricValue = Math.round(Number(statisticsMetric.metricValue) * 100.0);
                    metricValues.push(metricValue);
                    foundMetricValue = true;
                    break;
                }
            }

            if (!foundMetricValue) {
                metricValues.push(Math.round(Math.random() * 100.0));
            }
        }

        let series = {
            name: `设备周利用率`,
            type: 'line',
            smooth: true,
            itemStyle: {
                color: seriesItemColors[seriesArr.length % seriesItemColors.length],
            },
            areaStyle: {
                color: seriesItemColors[seriesArr.length % seriesItemColors.length],
            },
            data: metricValues,
        };

        // refresh echarts options
        echartsRef.current?.setOption({
            xAxis: [
                {data: timeNames}
            ],
            series: [series],
        });
    };

    // onload
    useEffect(()=> {
        loadWeeklyDeviceUtilizationStatisticsMetrics();

        let refreshTask = setInterval(()=> {
            loadWeeklyDeviceUtilizationStatisticsMetrics();
        }, 1000 * 3600);

        if (echartsDomRef.current!=null) {
            initECharts(echartsDomRef.current);
        }

        return ()=> {
            clearInterval(refreshTask);
            if(echartsRef.current!=null) {
                echartsRef.current.dispose();
            }
        }
    }, []);

    // refresh
    echartsRef.current?.resize();

    // render
    return (
        <DeviceAnalyseWrapper>
            <Content>
                <EChartWrapper ref={(dom)=> {echartsDomRef.current=dom}}>
                </EChartWrapper>
            </Content>
        </DeviceAnalyseWrapper>
    )
});

export default WeeklyDeviceUtilization;
