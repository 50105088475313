import axios, { AxiosResponse } from "axios";
import AxiosService from "./Axios";

class ProfileApi {
    
    public async getProfile(): Promise<AxiosResponse<any>> {
        return AxiosService.get('/user/profile');
    }

    public async updatePassword(request: UpdatePasswordRequest): Promise<AxiosResponse<any>> {
        return AxiosService.post('/user/profile/update-password', request);
    }

}

interface UpdatePasswordRequest {
    password: string,
    newPassword: string,
}

export default ProfileApi;