import React, { memo, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import AMapLoader from "@amap/amap-jsapi-loader";
import moment from "moment";
import * as echarts from "echarts";
import { DevicesMapWrapper } from "./DeviceMap.styled";
import DeviceApi from "../../../../../api/DeviceApi";
import StringUtils from "../../../../../util/StringUtils";
import DeviceMetricApi from "../../../../../api/DeviceMetricApi";
import EventManager from "../../common/event-manager/EventManager";

import './DeviceMap.scss';
import SeriesColorUtils from "../../../../../util/SeriesColorUtils";
import { isMobile, useMobileOrientation } from "react-device-detect";
import { DeviceStatusUtils } from "../../../../../components/ui-components/device-status/DeviceStatus";
import ScaleUtils from "../../../../../util/ScaleUtils";

interface DeviceMapProps {
    eventManager: EventManager
}

export const DeviceMap: React.FC<DeviceMapProps> = memo((props)=> {
    // api
    let deviceApi = new DeviceApi();
    let deviceMetricApi = new DeviceMetricApi();
    let {isLandscape} = useMobileOrientation();
    let navigate = useNavigate();

    // ref
    let deviceViewsRef = useRef([]);
    let aMapContainerRef = useRef(null as any);
    let aMapTypeRef = useRef(null as any);
    let amapRef = useRef(null as any);
    let deviceMarkersRef = useRef([]);
    let deviceInfoWindowRef = useRef(null);
    let locaContextRef = useRef({loca: null, pulseLinkLayer: null} as any);
    let moveEndCallbackRef = useRef(null as any);

    // state
    let [deviceViews, setDeviceViews] = useState([]);
    let [selectedDeviceView, setSelectedDeviceView] = useState(null);
    let [selectedDeviceMetrics, setSelectedDeviceMetrics] = useState(null);

    // functions
    let fetchSelectedDeviceMetrics = async (deviceId: string)=> {
        let deviceMetrics = (await deviceMetricApi.getLatestDeviceMetricsView(deviceId)).data.data;
        setSelectedDeviceMetrics(deviceMetrics);
    };

    let fetchRealtimeMetricsSeries = async (deviceId: string)=> {
        // fetch data
        let deviceMetricsSeriesList = (await deviceMetricApi.getRealtimeDeviceMetricsSeries(deviceId)).data.data;
        return deviceMetricsSeriesList;
    }

    let initalizeDeviceMapWrapper = (dom: HTMLElement)=> {
        if (dom == null) {
            return;
        }
        dom.style.transform = `scale(${1/ScaleUtils.getGlobalScale()})`;
        dom.style.transformOrigin = 'left top';
        dom.style.width = `${ScaleUtils.getGlobalScale() * 200.0}%`;
        dom.style.height = `${ScaleUtils.getGlobalScale() * 150.0}%`;
    };

    let initializeMap = (dom: HTMLElement)=> {
        AMapLoader.load({
            "key": "0c3d07d8ebae700b5652bbfc2cf18fb0", 
            "version": "2.0",
            "plugins": [],
            "Loca":{
                "version": '2.0.0'
            },
        }).then((AMap)=>{
            if (dom == null || aMapContainerRef.current == dom) {
                return;
            }
            aMapContainerRef.current = dom;
            const amap = new AMap.Map(dom, {
                pitchEnable: false,
                zoom: 5,
                zooms:[5,20],
                pitch: 25,
                center: [105.070696, 27.752075],
                mapStyle: "amap://styles/blue",
                viewMode: "3D",
            });
            aMapTypeRef.current = AMap;
            amapRef.current = amap;
            amap.on('moveend', onMoveEnd)
            refreshDeviceMarkers();
        }).catch((e)=> {
            console.log(e);
        });
    };

    let refreshDeviceMarkers = ()=> {
        let AMap = aMapTypeRef.current;
        let amap = amapRef.current;
        let deviceViews = deviceViewsRef.current;
        if (AMap==null || amap==null || deviceViews==null) {
            return;
        }
        let deviceMarkers = deviceMarkersRef.current;
        let deviceId2DeviceMarker: Map<string, any> = new Map();
        for(let deviceMarker of deviceMarkers) {
            deviceId2DeviceMarker.set(deviceMarker.deviceView.device.id, deviceMarker);
        }

        let deviceViewMap: Map<string, any> = new Map();
        for(let deviceView of deviceViews) {
            deviceViewMap.set(deviceView.device.id, deviceView);
        }

        let hasMarkerChanges = false;

        let toUpdateMarkers = deviceMarkers.filter((e)=> {
            return deviceViewMap.has(e.deviceView.device.id);
        }).map((e)=> {
            let deviceView = deviceViews.filter((deviceView)=> deviceView.device.id == e.deviceView.device.id)[0];
            if (deviceView != null) {
                if (deviceView.latestHeartBeatMetric.longitude != e.deviceView.latestHeartBeatMetric.longitude
                    || deviceView.latestHeartBeatMetric.latitude != e.deviceView.latestHeartBeatMetric.latitude
                    || deviceView.node.status != e.deviceView.node.status
                ) {
                    hasMarkerChanges = true;
                }

                e.marker.setOptions({
                    center: [deviceView.latestHeartBeatMetric.longitude, deviceView.latestHeartBeatMetric.latitude],
                    radius: Math.round(6 * ScaleUtils.getGlobalScale()),
                    fillColor: getDeviceMarkerColor(deviceView),
                    fillOpacity: 0.8,
                    strokeWeight: 0,
                    cursor: 'pointer',
                });
            }
            return e;
        });

        let toAddMarkers = deviceViews.filter((e)=> {
            return deviceId2DeviceMarker.has(e.device.id) == false;
        }).sort((a, b)=> {
            if (StringUtils.nonull(a.node.status).toUpperCase() == 'ONLINE') {
                return 1;
            }
            return -1;
        }).map((e)=> {
            let marker = new AMap.Marker({
                position: [e.latestHeartBeatMetric.longitude, e.latestHeartBeatMetric.latitude],
                content: StringUtils.nonull(e.node.status).toUpperCase() == 'ONLINE' ? `<div class="online-circle">
                    <div class="waves" style="animation: wave ease-out infinite 2s 0s"></div>
                    <div class="waves" style="animation: wave ease-out infinite 2s 0.3s"></div>
                    <div class="waves" style="animation: wave ease-out infinite 2s 0.6s"></div>
                </div>`
                : `<div class="offline-circle"></div>`,
                offset: new AMap.Pixel(-5, -5),
                cursor: 'pointer',
            });
            marker.setMap(amap);
            marker.on('click', ()=> {
                openDeviceInfoWindow(e);
            });
            return {
                deviceView: e,
                marker: marker,
            };
        });

        let toDeleteMarkers = deviceMarkers.filter((e)=> {
            return deviceViewMap.has(e.deviceView.device.id) == false;
        }).map((e)=> {
            e.setMap(null);
            return e;
        });

        let newDeviceMarkers = toUpdateMarkers.concat(toAddMarkers);
        deviceMarkersRef.current = newDeviceMarkers;

        if (toAddMarkers.length > 0 || toDeleteMarkers.length > 0 || hasMarkerChanges) {
            refreshPulseLinks();
        }
    }

    let openDeviceInfoWindow = async (deviceView)=> {
        let amap = amapRef.current;
        if (amap == null) {
            return;
        }

        let popupWrapper = document.createElement('div');
        popupWrapper.className='device-popup-window';

        // device details
        let deviceDetailsTitle = document.createElement('div');
        deviceDetailsTitle.className = 'group-title';
        deviceDetailsTitle.style.display = 'flex'
        deviceDetailsTitle.style.justifyContent = 'space-between'

        let deviceDtailsTitleText = document.createElement('div');
        deviceDtailsTitleText.innerText = '设备详情';

        let viewDetails = document.createElement('div');
        viewDetails.className = 'view-details';
        viewDetails.innerText = '查看更多设备详情';
        viewDetails.addEventListener('click', (e)=> {
            navigate(`/big-screen/device-details/${deviceView.device.id}`);
            // window.open(`/#/big-screen/device-details/${deviceView.device.id}`);
        });

        deviceDetailsTitle.appendChild(deviceDtailsTitleText);
        deviceDetailsTitle.appendChild(viewDetails);

        let deviceNameLabel = document.createElement('div');
        deviceNameLabel.className = 'field-label';
        deviceNameLabel.innerText = '设备名称';

        let deviceName = document.createElement('div');
        deviceName.className = 'field-value';

        let deviceSNLabel = document.createElement('div');
        deviceSNLabel.className = 'field-label';
        deviceSNLabel.innerText = '序列号SN';

        let deviceSN = document.createElement('div');
        deviceSN.className = 'field-value';
  
        let productModelLabel = document.createElement('div');
        productModelLabel.className = 'field-label';
        productModelLabel.innerText = '产品型号';

        let productModel = document.createElement('div');
        productModel.className = 'field-value';

        let firmwareVersionLabel = document.createElement('div');
        firmwareVersionLabel.className = 'field-label';
        firmwareVersionLabel.innerText = '固件版本';

        let firmwareVersion = document.createElement('div');
        firmwareVersion.className = 'field-value';

        let deviceStatusLabel = document.createElement('div');
        deviceStatusLabel.className = 'field-label';
        deviceStatusLabel.innerText = '设备状态';

        let deviceStatus = document.createElement('div');
        deviceStatus.className = 'field-value';

        let deviceRssiLabel = document.createElement('div');
        deviceRssiLabel.className = 'field-label';
        deviceRssiLabel.innerText = '信号强度';

        let deviceRssi = document.createElement('div');
        deviceRssi.className = 'field-value';

        let deviceLastOnlineTimeLabel = document.createElement('div');
        deviceLastOnlineTimeLabel.className = 'field-label';
        deviceLastOnlineTimeLabel.innerText = '最后在线时间';

        let deviceLastOnlineTime = document.createElement('div');
        deviceLastOnlineTime.className = 'field-value';

        let deviceDetailsGroup = document.createElement('div');
        deviceDetailsGroup.className = 'group-content';
        deviceDetailsGroup.appendChild(deviceDetailsTitle);

        let deviceDetailsWrapper = document.createElement('div');
        deviceDetailsWrapper.className = 'device-details-wrapper';
        deviceDetailsGroup.appendChild(deviceDetailsWrapper);

        let detailsColumn1 = document.createElement('div');
        detailsColumn1.className = 'field-column';
        deviceDetailsWrapper.appendChild(detailsColumn1);
        for(let [label, value] of [
            [deviceNameLabel, deviceName],
            [productModelLabel, productModel],
            [deviceLastOnlineTimeLabel, deviceLastOnlineTime],
        ]) {
            if (StringUtils.equalsIgnoreCase(deviceView.node.status, 'ONLINE') && label == deviceLastOnlineTimeLabel) {
                continue;
            }
            let fieldItem = document.createElement('div');
            fieldItem.className = 'field-item';
            fieldItem.appendChild(label);
            fieldItem.appendChild(value);
            detailsColumn1.appendChild(fieldItem);

            if (isMobile) {
                fieldItem.style.fontSize='14px';
            }
        }

        let detailsColumn2 = document.createElement('div');
        detailsColumn2.className = 'field-column';
        deviceDetailsWrapper.appendChild(detailsColumn2);
        for(let [label, value] of [
            [deviceStatusLabel, deviceStatus],
            [deviceRssiLabel, deviceRssi],
        ]) {
            let fieldItem = document.createElement('div');
            fieldItem.className = 'field-item';
            fieldItem.appendChild(label);
            fieldItem.appendChild(value);
            detailsColumn2.appendChild(fieldItem);
            
            if (isMobile) {
                fieldItem.style.fontSize='16px';
            }
        }

        // metrics
        let deviceMetricsTitle = document.createElement('div');
        deviceMetricsTitle.className = 'group-title';
        deviceMetricsTitle.innerText =  '实时数据'

        let deviceMetricsWrapper = document.createElement('div');
        deviceMetricsWrapper.className = 'device-metrics-wrapper';
        
        let deviceMetricsSeriesList = await fetchRealtimeMetricsSeries(deviceView.device.id)
        let deviceMetricsCharts = [];
        let deviceMetricsPropertyValues = [];
        for(let deviceMetricsSeries of deviceMetricsSeriesList) {
            let metrics = deviceMetricsSeries.metrics;
            let deviceMetricProperty = deviceMetricsSeries.property;
            let latestMetrics = metrics[metrics.length-1];

            let propertyName = document.createElement('div');
            propertyName.className='property-name'
            propertyName.innerText = deviceMetricProperty.name;

            let propertyValue = document.createElement('div');
            propertyValue.className = 'property-value';
            deviceMetricsPropertyValues.push(propertyValue);

            let chartDom = document.createElement('div');
            chartDom.className='property-chart';

            let propertyItem = document.createElement('div');
            propertyItem.className = 'property-item';
            propertyItem.appendChild(chartDom);
            propertyItem.appendChild(propertyName);
            propertyItem.appendChild(propertyValue);
            deviceMetricsWrapper.appendChild(propertyItem);
            
            // init echarts
            let chart = echarts.init(chartDom);
            deviceMetricsCharts.push(chart);

            // series
            let property = deviceMetricProperty;

            // init options
            let option = {
                backgroundColor: 'transparent',
                grid: {
                    x: 2,
                    y: 2,
                    x2: 2,
                    y2: 2,
                },
                xAxis: {
                    type: 'time',
                    splitLine: {
                        show: false,
                    },
                    axisLine: {
                        show: false,
                    },
                    axisTick: {
                        show: false,
                    }
                },
                yAxis: {
                    type: 'value',
                    boundaryGap: [0, '100%'],
                    splitLine: {
                        show: false,
                        lineStyle: {
                            color: '#CCCCCC',
                            width: 1,
                            type: 'dashed',
                        }
                    },
                    nameTextStyle: {
                        align: 'left',
                    },
                },
                dataZoom: [
                    {
                      type: 'inside',
                      start: 90,
                      end: 100
                    }
                ],
                series: [
                    {
                        name: property.name,
                        type: 'line',
                        color: SeriesColorUtils.getSeriesColor(deviceMetricsCharts.indexOf(chart)),
                        smooth: false,
                        showSymbol: false,
                        lineStyle: {
                            width: 1,
                            opacity: 0.3,
                        },
                        areaStyle: {
                            opacity: 0.2,
                        },
                        data: [],
                        shadowBlur: 20,
                    }
                ],
            };
            chart.setOption(option);
        }

        let deviceMetricsGroup = document.createElement('div');
        deviceMetricsGroup.className = 'group-content';
        deviceMetricsGroup.appendChild(deviceMetricsTitle);
        deviceMetricsGroup.appendChild(deviceMetricsWrapper);

        // assemble
        popupWrapper.appendChild(deviceDetailsGroup);
        popupWrapper.appendChild(deviceMetricsGroup);
        popupWrapper.style.transform = `scale(${ScaleUtils.getGlobalScale()})`;
        popupWrapper.style.transformOrigin = 'left top';

        let setData = (deviceView, deviceMetricsSeriesList)=> {
            // set device information
            deviceName.innerText = deviceView.device.name;
            deviceSN.innerText = deviceView.node.sn;

            productModel.innerText = deviceView.node.model;
            firmwareVersion.innerText = deviceView.node.firmwareVersion;
            
            let deviceStatusText = document.createElement('span');
            deviceStatusText.innerText = StringUtils.equalsIgnoreCase(deviceView.node.status, 'ONLINE') ? '在线' : '离线';

            let deviceStatusIcon = document.createElement('span');
            deviceStatusIcon.innerHTML=`
            <svg class="icon" aria-hidden="true">
                <use xlink:href="#icon-${StringUtils.equalsIgnoreCase(deviceView.node.status, 'ONLINE') ? 'online' : 'offline'}"></use>
            </svg>
            `;

            deviceStatus.innerHTML='';
            deviceStatus.appendChild(deviceStatusText);
            deviceStatus.appendChild(deviceStatusIcon);
            
            let deviceRssiText = document.createElement('span');
            deviceRssiText.innerText = deviceView.latestHeartBeatMetric.rssi + 'dbm';

            let deviceSignalStretchIcon = document.createElement('div');
            deviceSignalStretchIcon.style.display = 'inline-flex';
            deviceSignalStretchIcon.style.alignItems = 'flex-end';
            deviceSignalStretchIcon.style.height = '10px';
            deviceSignalStretchIcon.style.padding = '0px 10px';

            deviceRssi.innerHTML = '';
            deviceRssi.appendChild(deviceRssiText);
            deviceRssi.appendChild(deviceSignalStretchIcon);

            let signalStrengthColor = '';
            let signalStrength = 5;
            for(let [strength, rssi, color] of [
                [5, -60, '#1EA77B'],
                [4, -68, '#0092f5'],
                [3, -75, '#0092f5'],
                [2, -80, '#E70302'],
                [1, -90, '#E70302'],
            ]) {
                if (deviceView.latestHeartBeatMetric.rssi > rssi) {
                    signalStrength = strength as any;
                    signalStrengthColor = color as any;
                    break;
                }
            }

            for(let i=1; i<=5; i++) {
                let signalBlock = document.createElement('div');
                signalBlock.style.margin='0px 1px 0px 0px';
                signalBlock.style.width = '2px';
                signalBlock.style.height = 10 * i/5 + 'px';
                signalBlock.style.backgroundColor = signalStrength < i ? 'rgba(255, 255, 255, 0.3)' : signalStrengthColor;
                deviceSignalStretchIcon.appendChild(signalBlock);
            }

            deviceLastOnlineTime.innerText = moment(parseInt(deviceView.latestHeartBeatMetric.timestamp)).format('YYYY/MM/DD HH:mm:ss');

            // refresh charts
            for(let i=0; i<deviceMetricsSeriesList.length; i++) {
                let deviceMetricsSeries = deviceMetricsSeriesList[i];
                let metrics = deviceMetricsSeries.metrics;
                let deviceMetricProperty = deviceMetricsSeries.property;

                // property value
                let latestMetrics = metrics[metrics.length-1];
                let propertyValue = deviceMetricsPropertyValues[i];
                propertyValue.innerText = latestMetrics.value + StringUtils.nonull(deviceMetricProperty.unit);
                if (deviceMetricProperty.type == 'FLOAT32') {
                    propertyValue.innerText = parseFloat(latestMetrics.value).toFixed(deviceMetricProperty.scale) + StringUtils.nonull(deviceMetricProperty.unit);
                }

                // charts
                let seriesData = [];
                let lastMetricTimestamp: number = null;
                let paddingGapMillis = 5 * 10000;
                for (let i = 0; i < metrics.length; i++) {
                    let metric = metrics[i];
                    
                    // pad data point
                    if (lastMetricTimestamp !=null && Math.abs(metric.timestamp - lastMetricTimestamp) > paddingGapMillis) {
                        // down data point
                        let downDataPoint = {name: '' + (Number(lastMetricTimestamp)+1), value: [(Number(lastMetricTimestamp)+1), 0]};
                        seriesData.push(downDataPoint);

                        // up data point
                        let upDataPoint: any = {name: '' + (Number(metric.timestamp)-1), value: [(Number(metric.timestamp)-1), 0]};
                        seriesData.push(upDataPoint);
                    }
                    lastMetricTimestamp = metric.timestamp;

                    // add to series data
                    seriesData.push({
                        name: metric.timestamp + '',
                        value: [Number(metric.timestamp), Number(metric.value)],
                    });
                }

                // echarts options
                let chart = deviceMetricsCharts[i];
                chart.setOption({
                    series: [{
                        data: seriesData
                    }]
                });
                chart.resize();
            }
        };

        // calculate scaled width/height
        let scaleWrapper = document.createElement('div');
        scaleWrapper.appendChild(popupWrapper);
        scaleWrapper.style.position = 'absolute';
        scaleWrapper.style.overflow = 'hidden';
        // scaleWrapper.style.visibility = 'hidden';
        scaleWrapper.style.zIndex = '200';

        // set data first to calculate width/height
        document.body.appendChild(scaleWrapper);
        setData(deviceView, deviceMetricsSeriesList);

        // get layout width/height
        let originalClientWidth=scaleWrapper.clientWidth;
        let originalClientHeight=scaleWrapper.clientHeight;
        scaleWrapper.remove();

        // set width/height statically
        scaleWrapper.style.position = 'static';
        scaleWrapper.style.visibility = 'visible';
        scaleWrapper.style.width = `${originalClientWidth * ScaleUtils.getGlobalScale()}px`;
        scaleWrapper.style.height = `${originalClientHeight * ScaleUtils.getGlobalScale()}px`;

        // add to map
        let infoWindow = new AMap.InfoWindow({
            content: scaleWrapper,
            anchor: 'bottom-center',
            offset: [0, -20],
        });

        if (deviceInfoWindowRef.current) {
            deviceInfoWindowRef.current.close();
        }
        infoWindow.open(amap, [deviceView.latestHeartBeatMetric.longitude, deviceView.latestHeartBeatMetric.latitude], 0);
        deviceInfoWindowRef.current=infoWindow;

        // refresh task
        let deviceId = deviceView.device.id;
        let refreshTaskId = window.setInterval(async ()=> {
            let deviceView = (await deviceApi.getDeviceViewByDeviceId(deviceId)).data.data;
            let deviceMetricsSeriesList = await fetchRealtimeMetricsSeries(deviceId);
            setData(deviceView, deviceMetricsSeriesList);
        }, 5000);

        setTimeout(()=> {
            setData(deviceView, deviceMetricsSeriesList);
        });

        infoWindow.on('close', (e)=> {
            window.clearInterval(refreshTaskId);
            for(let chart of deviceMetricsCharts) {
                chart.dispose();
            }
        });
    };

    let refreshPulseLinks = ()=> {
        if (isMobile) {
            return;
        }
        let amap = amapRef.current;
        let AMap = aMapTypeRef.current;
        let deviceViews = deviceViewsRef.current;
        if (AMap==null || amap==null || deviceViews==null) {
            return;
        }

        let locaContext = locaContextRef.current;
        let Loca = (window as any).Loca;
        let loca = locaContext.loca;
        if (loca == null) {
            loca = new Loca.Container({map: amap});
            locaContext.loca = loca;
        }
        if (locaContext?.pulseLinkLayer) {
            loca.remove(locaContext.pulseLinkLayer);
        }

        /*
        var scatter = new Loca.ScatterLayer({
            loca,
            zIndex: 10,
            opacity: 0.6,
            visible: true,
            zooms: [2, 22],
        });

        let pointGeoData = {
            type: "FeatureCollection",
            features: [],
        }
        for(let deviceView of deviceViews) {
            pointGeoData.features.push({
                "type": "Feature",
                "geometry": {
                    "type": "Point",
                    "coordinates": [deviceView.latestHeartBeatMetric.longitude, deviceView.latestHeartBeatMetric.latitude],
                }
            });
        }

        scatter.setSource(new Loca.GeoJSONSource({data: pointGeoData}));
        scatter.setStyle({
            unit: 'meter',
            size: function (index, feat) {
                return (14 - map.getZoom(2))/14 * 300000;
            },
            borderWidth: 0,
            texture: 'https://a.amap.com/Loca/static/loca-v2/demos/images/breath_red.png',
            duration: 2000,
            animate: true,
        });
        */
        // loca.add(scatter);

        var pulseLink = new Loca.PulseLinkLayer({
            // loca,
            zIndex: 10,
            opacity: 1,
            visible: true,
            zooms: [2, 22],
            depth: true,
        });
        locaContext.pulseLinkLayer = pulseLink;

        let pulseLinkGeoData = {
            type: "FeatureCollection",
            features: [],
        };
        for(let deviceView of deviceViews) {
            if (deviceView.node.status == 'OFFLINE') {
                continue;
            }
            pulseLinkGeoData.features.push({
                "type": "Feature",
                "geometry": {
                    "type": "LineString",
                    "coordinates": [
                        [deviceView.latestHeartBeatMetric.longitude, deviceView.latestHeartBeatMetric.latitude],
                        [120.070696,31.752075],
                    ]
                }
            });
        }

        /*
        for(let point of [
            [90.444058,30.463054],
            [87.510223,43.673971],
            [86.499481,41.63817],
            [125.100123,46.531522],
            [123.891627,47.177631],
            [122.814967,45.693764],
            [123.430201,41.708862],
            [118.903834,42.085031],
            [111.586939,40.717137],
            [106.247584,38.413847],
            [103.874537,35.999181],
            [106.445338,29.557996],
            [104.050318,30.338576],
            [109.016138,34.510015],
            [120.070696,31.752075],
        ]) {
            pulseLinkGeoData.features.push({
                "type": "Feature",
                "geometry": {
                    "type": "LineString",
                    "coordinates": [
                        point,
                        [120.070696,31.752075],
                    ]
                }
            });
        }
        */

        pulseLink.setSource(new Loca.GeoJSONSource({data: pulseLinkGeoData}));
        pulseLink.setStyle({
            unit: 'px',
            dash: [40, 0, 40, 0],
            lineWidth: function () {
                return [2, 2];
            },
            height: function (index, feat) {
                return feat.distance / 3 + 10;
            },
            smoothSteps: 90,
            speed: 30,
            flowLength: 100,
            lineColors: function (index, feat) {
                return ['rgba(0, 0, 0, 0)', 'rgba(0, 0, 0, 0)', 'rgba(0, 0, 0, 0)'];
            },
            maxHeightScale: 0.5,
            headColor: 'rgba(255, 255, 0, 1)',
            trailColor: 'rgba(255, 255,0, 0)',
        });
        loca.add(pulseLink);
        loca.animate.start();
    };

    // events
    let onMarkerClick = (deviceView)=> {
        setSelectedDeviceView(deviceView);
        openDeviceInfoWindow(deviceView);
    };

    let onMoveEnd = ()=> {
        let callback = moveEndCallbackRef.current;
        if (callback) {
            callback();
        }
    };

    // onload
    useEffect(()=> {
        let loadDeviceViews = async ()=> {
            let deviceViews = (await deviceApi.getAllDeviceViews()).data.data;
            setDeviceViews(deviceViews);
            deviceViewsRef.current = deviceViews;
            refreshDeviceMarkers();
        }
        loadDeviceViews();

        let refreshTask = setInterval(()=> {
            loadDeviceViews();
            if (selectedDeviceView) {
                fetchSelectedDeviceMetrics(selectedDeviceView.device.id);
            }
        }, 5000);

        let onDeviceSelected = (selectedDeviceId)=> {
            let deviceView = deviceViewsRef.current.filter((e)=> e.device.id == selectedDeviceId)[0];
            if (deviceView) {
                if (amapRef.current) {
                    let amap = amapRef.current;
                    amap.setZoomAndCenter(11, [deviceView.latestHeartBeatMetric.longitude, deviceView.latestHeartBeatMetric.latitude], false, 500);
                    moveEndCallbackRef.current = ()=> {
                        onMarkerClick(deviceView);
                        moveEndCallbackRef.current = null;
                    }
                }
            }
        };
        props.eventManager.addDeviceSelectedListener(onDeviceSelected);

        return ()=> {
            clearInterval(refreshTask);
            props.eventManager.removeDeviceSelectedListener(onDeviceSelected);
            locaContextRef.current?.loca?.destroy();
            deviceInfoWindowRef.current?.close();
            amapRef.current?.destroy();
        }
    }, []);

    // render
    let getDeviceMarkerColor = (deviceView: any)=> {
        return DeviceStatusUtils.getDeviceMarkerColor(deviceView.node.status);
    };

    return (
        <DevicesMapWrapper ref={(dom)=> initalizeDeviceMapWrapper(dom)}>
            <div id="map-container" style={{width: "100%", height: "100%"}} ref={(dom)=> initializeMap(dom)}>
            </div>
        </DevicesMapWrapper>
    )
});