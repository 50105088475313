import styled from "styled-components";
import TitleBackground from './assets/title-background.png'

export const BorderDecoratedCardWrapper = styled.div`
    display: flex !important;
    flex-direction: column;
    align-items: stretch;
    overflow: hidden;
    padding: 10px;
    box-sizing: border-box;
`;

export const Title = styled.div`
    color: rgb(189, 226, 243);
    box-sizing: border-box;
    display: flex; 
    flex-direction: row;
    align-items: flex-end;
    background: url(${TitleBackground});
    background-size: 100%;
    background-position: 0% 50%;
    background-size: 100% 200%;
    height: 25px;
`;

export const TitleCn = styled.div`
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    letter-spacing: 4px;
    width: 50%;
    padding-left: 50px;
    padding-bottom: 5px;
`;

export const TitleEn = styled.div`
    font-size: 12px;
    font-weight: normal;
    font-style: normal;
    letter-spacing: 2px;
    padding-bottom: 5px;
    font-family: Rajdhani;
`;

export const TitleBorder = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 20px;
`;

export const Content = styled.div`
    flex: 1 1 auto;
    overflow: hidden;
`;

