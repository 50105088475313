import { message } from "antd";
import MessageService from "./MessageService";

interface MessageServiceLoaderProps {

}

let MessageServiceLoader: React.FC<MessageServiceLoaderProps> = (props)=> {

    // global message
    let [messageApi, contextHolder] = message.useMessage();
    MessageService.getInstance().setMessageApi(messageApi);

    return <div style={{position: 'absolute', visibility: 'hidden', width: '0', height: '0'}}>
        {contextHolder}
    </div>
};

export default MessageServiceLoader;
