import { MessageInstance } from "antd/es/message/interface";

class MessageService {

    private messageApi: MessageInstance;

    public setMessageApi(messageApi: MessageInstance): void {
        this.messageApi = messageApi;
    }

    public showMessage(type: string, content: string): void {
        let messageApi = this.messageApi;
        if (messageApi == null) {
            return;
        }
        messageApi.open({
            type: type as any,
            content: content,
        });
    }
    
    private static messageService = new MessageService();

    public static getInstance(): MessageService {
        return this.messageService;
    }
}

export default MessageService;

