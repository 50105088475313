import { useState } from "react";
import { UpdatePasswordButtonsWrapper, UpdatePasswordFormDescription, UpdatePasswordFormTitle, UpdatePasswordFormWrapper, UpdatePasswordWrapper } from "./UpdatePassword.styled";
import { Button, Form, Input, message } from "antd";
import ProfileApi from "../../../api/ProfileApi";
import StringUtils from "../../../util/StringUtils";
import ViewStateService from "../../../services/view-state/ViewStateService";


interface UpdatePasswordProps {

}

let UpdatePassword: React.FC<UpdatePasswordProps> = (props)=> {
    // api
    let profileApi = new ProfileApi();
    let viewStateService = ViewStateService.getInstance();
    const [messageApi, contextHolder] = message.useMessage();
    viewStateService.setStatusBarTitle('安全设置');

    // state
    let [password, setPassword] = useState('');
    let [newPassword, setNewPassword] = useState('');
    let [confirmPassword, setConfirmPassword] = useState('');
    let [submitEnabled, setSubmitEnabled] = useState(false);
    
    // form
    const [form] = Form.useForm();

    // events
    let validateForm = ()=> {
        form.validateFields().then((values)=> {
            setSubmitEnabled(true);
        }).catch((err)=> {
            console.log(err);
        });
    }

    let passwordValidator = (rule, value, callback)=> {
        if (value.length < 6) {
            callback("密码长度不能少于6位");
        } else {
            let valid = StringUtils.equalsIgnoreCase(newPassword, confirmPassword);
            if (!valid) {
                callback("密码不一致");
            } else {
                callback();
            }
        }
    }

    let onSubmit = async ()=> {
        await profileApi.updatePassword({password, newPassword});
        messageApi.open({
            type: 'success',
            content: '修改密码成功',
        });
        setPassword('');
        setNewPassword('');
        setConfirmPassword('');
        form.resetFields();
    };

    // render
    return <UpdatePasswordWrapper>
            <UpdatePasswordFormWrapper>
                {contextHolder}
                <UpdatePasswordFormTitle>更改密码</UpdatePasswordFormTitle>
                <UpdatePasswordFormDescription>在这里，您可以轻松更新您的账户密码，确保个人信息与数据安全。密码是保护您账户安全的第一道防线，因此，我们强烈建议您定期更换密码，并选择包含大小写字母、数字和特殊字符的复杂组合，以增强密码的安全性。修改密码的过程简单快捷，您只需按照页面提示，输入当前密码，再输入两次新密码进行确认即可。</UpdatePasswordFormDescription>
                <Form form={form} name="form" layout="vertical">
                    <Form.Item name="name" label="当前密码" rules={[{required: true}]}>
                        <Input.Password placeholder="请输入用户当前密码" value={password} onChange={(e)=> {setPassword(e.target.value); validateForm();}} />
                    </Form.Item>

                    <Form.Item name="newPassword" label="新密码（长度不能少于6位）" rules={[{required: true}, {validator: passwordValidator}]}>
                        <Input.Password placeholder="请输入新密码" value={newPassword} onChange={(e)=> {setNewPassword(e.target.value); validateForm();}} />
                    </Form.Item>

                    <Form.Item name="confirmPassword" label="再次输入新密码" rules={[{required: true}, {validator: passwordValidator}]}>
                        <Input.Password placeholder="请再次输入新密码" value={confirmPassword} onChange={(e)=> {setConfirmPassword(e.target.value); validateForm();}} />
                    </Form.Item>

                    <UpdatePasswordButtonsWrapper>
                        <Button type="primary" onClick={onSubmit} disabled={!submitEnabled}>确定</Button>
                    </UpdatePasswordButtonsWrapper>
                </Form>
            </UpdatePasswordFormWrapper>
    </UpdatePasswordWrapper>
};

export default UpdatePassword;
