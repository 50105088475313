

import styled from "styled-components";

export const DeviceOnlineDurationWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: stretch;
    padding: 10px;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    letter-spacing: 1px;
    font-size: 12px;
    color: #FFFFFF;
`;

export const Content = styled.div`
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    position: relative;
    overflow-y: hidden;
`;

export const DeviceOnlineDurationItem = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    box-sizing: border-box;
    cursor: pointer;
    padding: 5px 5px;
    line-height: 1.2;

    &:hover {
        background: linear-gradient(0deg, rgba(55, 72, 108, 0.5) 0%, rgba(55, 72, 108, 0.0) 50%, rgba(55, 72, 108, 0.5) 100%);
    }
`;

export const DeviceIndex = styled.div`
    flex: 0 0 auto;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 30px;
    padding: 5px 10px 5px 5px;
    margin-right: 10px;
    border-radius: 3px;
    text-align: center;
    background-color: rgba(0, 0, 0, 0.2);
    color: rgba(255, 255, 255, 0.3);
`;

export const DeviceNameAndDuration = styled.div`
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    align-items: stretch;

    & .ant-progress {
        font-size: 0px;
        line-height: 1;
    }

    & .ant-progress-outer {
        font-size: 0px;
        line-height: 1;
    }

    & .ant-progress-inner {
        background-color: rgba(255, 255, 255, 0.2);
    }

    & .ant-progress-text {
        height: 0px;
        color: rgb(100, 200, 255);
        font-size: 12px;
        font-style: normal;
        overflow: visible;
        display: flex;
        align-items: center;
    }
`;

export const DeviceName = styled.div`
    flex: 0 0 auto;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 100px;
    font-style: normal;
    padding: 0px 0px 2px 2px; 
`;

export const DurationText = styled.div`
    width: 80px;
    text-align: left;
`;
