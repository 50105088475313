
import styled from "styled-components";

export const DeviceListWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: stretch;
    padding: 10px;
    width: 100%;
    height: 100%;
    overflow: hidden;
    box-sizing: border-box;
`;

export const Content = styled.div`
    flex: 1 1 auto;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    padding: 10px 0px 0px 0px;
    box-sizing: border-box;
`;

export const TableHeader = styled.div`
    display: flex;
    flex-direction: column;
    align-items: stretch;
    padding-bottom: 5px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    margin-bottom: 5px;
`;

export const TableContent = styled.div`
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    overflow: hidden;
    position: relative;

    &::-webkit-scrollbar {
        width: 0px;
        display: none;
    }
`;

export const TableHeaderRow = styled.div`
    display: grid;
    width: 100%;
    grid-template-columns: 1.75fr 1fr 1fr 1fr;
    grid-gap: 5px;
    align-items: center;
    justify-items: stretch;
    background-color: rgba(255, 255, 255, 0.05);
    font-size: 12px;
    font-style: normal;
    
    & div {
        padding-top: 3px;
        padding-bottom: 3px;
        background-color: rgba(255, 255, 255, 0.1);
    }
`;

export const TableRow = styled.div`
    display: grid;
    width: 100%;
    grid-template-columns: 1.75fr 1fr 1fr 1fr;
    grid-gap: 5px;
    align-items: center;
    justify-items: stretch;
    font-size: 12px;
    font-style: normal;
    padding: 4px 0px;
    cursor: pointer;
    position: relative;

    &::after {
        content: '';
        position: absolute;
        height: 1px;
        width: 100%;
        bottom: 0px;
        background: linear-gradient(90deg, rgba(255, 255, 255, 0.025) 0%, rgba(255, 255, 255, 0.075) 50%, rgba(255, 255, 255, 0.025) 100%);
    }
    
    &:nth-child(even) {
        
    }
    
    &:hover {
        background: linear-gradient(0deg, rgba(55, 72, 108, 0.5) 0%, rgba(55, 72, 108, 0.0) 50%, rgba(55, 72, 108, 0.5) 100%);
    }

    &:hover:nth-child(even) {
        background: linear-gradient(0deg, rgba(55, 72, 108, 0.5) 0%, rgba(55, 72, 108, 0.0) 50%, rgba(55, 72, 108, 0.5) 100%);
    }

`;

export const DeviceName = styled.div`
    white-space: nowrap;
    text-overflow: ellipsis;
    padding: 0px 5px;
`;

export const DeviceHealth = styled.div`
    white-space: nowrap;
    text-overflow: ellipsis;
    text-align: center;
`;

export const DeviceStatus = styled.div`
    white-space: nowrap;
    text-overflow: ellipsis;
    display: flex;
    justify-content: center;
`;

export const DeviceRssiValue = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const StatusText = styled.div`
    margin: 3px 5px 0px 5px;
`;

export const OnlineStatus = styled.div`
    display: flex;
    align-items: center;
    padding: 3px 10px;
    color: #FFFFFF;
    white-space: nowrap;
    text-overflow: ellipsis;
`;

export const OfflineStatus = styled.div`
    display: flex;
    align-items: center;
    padding: 3px 10px;
    color: #FFFFFF;
    white-space: nowrap;
    text-overflow: ellipsis;
`;