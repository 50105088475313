import styled from "styled-components";

export const GridBackgroundWrapper = styled.div`
    display: grid;
    justify-items: stretch;
    align-items: stretch;
    width: 100%;
    height: 100%;
`;

export const Cell = styled.div`
    border-left: 1px solid rgba(255, 255, 255, 0.05);
    border-top: 1px solid rgba(255, 255, 255, 0.05);
    z-index: 99;
`;



