import axios, { AxiosResponse } from "axios";
import AxiosService from "./Axios";

class StatisticsMetricApi {
    
    public async query(request: QueryStatisticsMetricsRequest): Promise<AxiosResponse<any>> {
        return AxiosService.post('/statistics-metric/query', request);
    }
}

interface QueryStatisticsMetricsRequest {
    
    objectIds: string[];
    metricNames: string[];
    timeScale: string;

    startTime: number;
    endTime: number;
}

export default StatisticsMetricApi;