import { Button, Form, Input, Space } from "antd";
import { useEffect, useState } from "react";
import { FormWrapper, OperationWrapper, Title, TitleWrapper, UpdateDeviceInformationWrapper } from "./UpdateDeviceInformation.styled";
import DeviceApi from "../../../../../../../api/DeviceApi";

interface UpdateDeviceInformationProps {
    deviceView: any,
    onClose: ()=> void,
    onComplete: ()=> void,
}

export const UpdateDeviceInformation: React.FC<UpdateDeviceInformationProps> = (props)=> {
    // api
    let deviceApi = new DeviceApi();

    // form
    const [form] = Form.useForm();

    // state
    let [deviceInformation, setDeviceInformation] = useState({
        name: props.deviceView.device.name,
        deviceNo: props.deviceView.device.deviceNo,
        remarks: props.deviceView.device.remarks,
    });
    let [submitEnabled, setSubmitEnabled] = useState(false);

    // validate
    let validateForm = ()=> {
        form.validateFields().then((values)=> {
            setSubmitEnabled(true);
        }).catch((err)=> {
            console.log(err);
        });
    };

    // events
    let onDeviceNameChange = (e)=> {
        deviceInformation.name = e.target.value;
        setDeviceInformation({...deviceInformation});
        validateForm();
    };

    let onDeviceNoChange = (e)=> {
        deviceInformation.deviceNo = e.target.value;
        setDeviceInformation({...deviceInformation});
        validateForm();
    };

    let onRemarksChange = (e)=> {
        deviceInformation.remarks = e.target.value;
        setDeviceInformation({...deviceInformation});
        validateForm();
    };

    let onSubmit = async ()=> {
        await deviceApi.update({
            ...deviceInformation,
            positioningMethod: 'AUTO',
        });
        props.onComplete();
        props.onClose();
    };

    let onCancel = ()=> {
        props.onClose();
    };

    // remarks
    return (
        <UpdateDeviceInformationWrapper>
            <TitleWrapper>
                <Title>编辑设备</Title>
            </TitleWrapper>

            <FormWrapper>
                <Form form={form} name="form" layout="vertical">
                    <Form.Item name="name" label="设备名称" rules={[{required: true}]} initialValue={deviceInformation.name}>
                        <Input onChange={onDeviceNameChange} />
                    </Form.Item>

                    <Form.Item name="deviceNo" label="设备编号" rules={[{required: true}]} initialValue={deviceInformation.deviceNo}>
                        <Input onChange={onDeviceNoChange} />
                    </Form.Item>

                    <Form.Item name="deviceTemplateName" label="设备模板" rules={[{required: true}]} initialValue={props.deviceView.deviceTemplate.name}>
                        <Input disabled={true}/>
                    </Form.Item>

                    <Form.Item name="nodeSn" label="设备序列号" rules={[{required: true}]} initialValue={props.deviceView.node.sn}>
                        <Input disabled={true}/>
                    </Form.Item>

                    <Form.Item name="remarks" label="备注" rules={[{required: false}]} initialValue={deviceInformation.remarks}>
                        <Input.TextArea style={{height: '100px'}} onChange={onRemarksChange}/>
                    </Form.Item>
                </Form>
            </FormWrapper>

            <OperationWrapper>
                <Space size="middle">
                    <Button type="primary" disabled={!submitEnabled} onClick={onSubmit}>确定</Button>
                    <Button type="primary" danger onClick={onCancel}>取消</Button>
                </Space>
            </OperationWrapper>
        </UpdateDeviceInformationWrapper>
    );
};

export default UpdateDeviceInformation;
