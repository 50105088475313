import styled from "styled-components";

export const UpdatePasswordWrapper = styled.div`
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
    position: relative;
`;

export const UpdatePasswordFormWrapper = styled.div`
    flex: 0 0 auto;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    width: 600px;
`;

export const UpdatePasswordFormTitle = styled.div`
    margin-top: 40px;
    margin-bottom: 0px;
    font-size: 24px;
    font-weight: bold;
    color: #101010;
    text-align: center;
    letter-spacing: 2px;
`;

export const UpdatePasswordFormDescription = styled.div`
    margin-top: 10px;
    font-size: 13px;
    line-height: 200%;
    color: #333333;
    text-align: justify;
    margin-bottom: 30px;
`;

export const UpdatePasswordButtonsWrapper = styled.div`
    display: flex;
    justify-content: flex-end;
`;
