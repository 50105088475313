import { CSSProperties } from "styled-components";
import { Cell, GridBackgroundWrapper } from "./GridBackground.styled";

interface GridBackgroundProps {
    rows: number,
    cols: number,
    style?: CSSProperties,
}


let GridBackground: React.FC<GridBackgroundProps> = (props)=> {
    let indexes = [];
    let {rows, cols} = props;
    for(let i=0; i<rows; i++) {
        for(let j=0; j<cols; j++) {
            let opacity = Math.pow(1 - Math.abs(i+0.5)/(rows + 1), 2);
            indexes.push({seq: i*cols+j, row: i, col: j, opacity: opacity});
        }
    }

    return <GridBackgroundWrapper style={{gridTemplateRows: `repeat(${props.rows}, 1fr)`, gridTemplateColumns: `repeat(${props.cols}, 1fr)`, ...props.style}}>
        {
            (indexes).map((e)=> {
                return <Cell key={e.seq} style={{opacity: e.opacity}} />
            })
        }
    </GridBackgroundWrapper>
}

export default GridBackground;
