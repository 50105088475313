import DeviceTemplateApi from "../../api/DeviceTemplateApi";
import { SearchOutlined } from "@ant-design/icons";
import { Button, Input, Modal, Space, Table, message } from "antd";
import { ColumnType } from "antd/es/table";
import { useEffect, useState } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import moment from "moment";

import StringUtils from "../../util/StringUtils";
import DeviceTemplateDetails from "./components/device-template-details/DeviceTemplateDetails";
import { DeviceTemplateManagementWrapper, TableWrapper, ToolBarLeftWrapper, ToolBarRightWrapper, ToolBarWrapper } from "./DeviceTemplateManagement.styled";
import { CreateDeviceTemplateWizard } from "./components/create-device-template-wizard/CreateDeviceTemplateWizard";
import DeviceTemplateUtils from "./util/DeviceTemplateUtils";

interface DeviceTemplateManagementProps {

}

const DeviceTemplateManagement: React.FC<DeviceTemplateManagementProps> = (props)=> {
    // api
    let deviceTemplateApi = new DeviceTemplateApi();
    const [messageApi, contextHolder] = message.useMessage();
    let navigate = useNavigate();

    // state
    let [loading, setLoading] = useState(true);
    let [deviceTemplateViews, setDeviceTemplateViews] = useState([]);
    let [openCreateDeviceTemplateModal, setOpenCreateDeviceTemplateModal] = useState(false);
    let [searchValue, setSearchValue] = useState("");

    // events
    let onEditDeviceTemplateDetails = (deviceTemplateView: any)=> {
        navigate(`./device-template/${deviceTemplateView.deviceTemplate.id}`);
    };

    let onViewDevices = (deviceTemplateView: any)=> {
        navigate(`/device-management?deviceTemplateId=${deviceTemplateView.deviceTemplate.id}`)
    };

    let onAddDeviceTemplateClick = ()=> {
        setOpenCreateDeviceTemplateModal(true);
    };

    let onDeleteDeviceTemplate = async (deviceTemplateView: any)=> {
        await deviceTemplateApi.delete(deviceTemplateView.deviceTemplate.id);
        messageApi.open({
            type: 'success',
            content: '删除设备模板成功',
        });
        loadDeviceTemplateViews();
    };

    let onInputChange = (e)=> {
        setSearchValue(e.target.value);
    };

    // load deviceTemplate views
    let loadDeviceTemplateViews = async ()=> {
        setLoading(true);
        let deviceTemplateViews = (await deviceTemplateApi.getAllDeviceTemplateViews()).data.data;
        setDeviceTemplateViews(deviceTemplateViews);
        setLoading(false);
    }

    // column definition
    let columns: ColumnType<any>[] = [
        {
            title: '模板名称',
            dataIndex: 'deviceTemplate.name',
            key: 'deviceTemplate.name',
            align: 'center',
            render: (text, record, index)=> {
                return <a onClick={()=> onEditDeviceTemplateDetails(record)}>{record.deviceTemplate.name}</a>
            }
        },
        {
            title: '产品型号',
            dataIndex: 'product.model',
            key: 'product.model',
            align: 'center',
            render: (text, record, index)=> {
                return <span>{record.deviceTemplate.productModel}</span>
            }
        },
        {
            title: '节点类型',
            dataIndex: 'deviceTemplate.nodeType',
            key: 'deviceTemplate.nodeType',
            align: 'center',
            render: (text, record, index)=> {
                return <span>{ DeviceTemplateUtils.nodeType2Text(record.deviceTemplate.nodeType) }</span>
            }
        },
        {
            title: '设备数量',
            dataIndex: 'statistics.deviceCount',
            key: 'statistics.deviceCount',
            align: 'center',
            render: (text, record, index)=> {
                return <span>{ record.statistics.deviceCount }</span>
            }
        },
        {
            title: '备注',
            dataIndex: 'deviceTemplate.remarks',
            key: 'deviceTemplate.remarks',
            align: 'center',
            render: (text, record, index)=> {
                return <span>{StringUtils.emptyToSlash(record.deviceTemplate.remarks)}</span>
            }
        },
        {
            title: '操作',
            key: 'action',
            align: 'center',
            render: (text, record, index)=> {
                return <Space size="middle">
                    <a onClick={()=> onEditDeviceTemplateDetails(record)}>编辑模板</a>
                    <a onClick={()=> onViewDevices(record)}>管理设备</a>
                    <a onClick={()=> onDeleteDeviceTemplate(record)}>删除模板</a>
                </Space>
            }
        }
    ];

    let filteredDeviceTemplateViews = [].concat(deviceTemplateViews);
    if (searchValue) {
        filteredDeviceTemplateViews = filteredDeviceTemplateViews.filter((e)=> {
            if (e.deviceTemplate.name.indexOf(searchValue)>=0 
            || e.deviceTemplate.productModel.indexOf(searchValue)>=0) {
                return true;
            }
            return false;
        });
    }

    // effect
    useEffect(()=> {
        loadDeviceTemplateViews();
    }, []);
    
    // render
    return (
        <DeviceTemplateManagementWrapper>
            {contextHolder}
            <ToolBarWrapper>
                <ToolBarLeftWrapper>
                    <Space size="middle">
                        <Button type="primary" onClick={onAddDeviceTemplateClick}>创建模板</Button>
                        <Button type="primary" danger={true}>批量删除</Button>
                    </Space>
                </ToolBarLeftWrapper>

                <ToolBarRightWrapper>
                    <Input placeholder="请输入模板名称" suffix={<SearchOutlined />} size="large" onChange={onInputChange} />
                </ToolBarRightWrapper>

                <Modal open={openCreateDeviceTemplateModal} 
                    onClose={()=> setOpenCreateDeviceTemplateModal(false)} 
                    footer={null} 
                    closable={false}
                    width={1000}
                    height={600}
                    >
                    <CreateDeviceTemplateWizard onClose={()=> setOpenCreateDeviceTemplateModal(false)} onComplete={()=> loadDeviceTemplateViews()}/>
                </Modal>
            </ToolBarWrapper>
            <TableWrapper>
                <Table 
                    rowKey={(record)=> record.deviceTemplate.id}
                    columns={columns} 
                    dataSource={filteredDeviceTemplateViews}
                    loading={loading}
                />
            </TableWrapper>

            <Routes>
                <Route path="device-template/:deviceTemplateId" element={<DeviceTemplateDetails onClose={()=>{}} onComplete={()=>{}}/>} />
            </Routes>
        </DeviceTemplateManagementWrapper>
    );
}

export default DeviceTemplateManagement;
